<script>
export default {
    props: {
        tabs: {
            type: Array,
            default() {
                return []
            }
        },
        theme: {
            type: String,
            default() {
                return 'normal'
            }
        },
        selectedTab: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            selected: 0,
        }
    },
    methods: {
        select(index) {
            this.selected = index
        }
    },
    mounted() {
        this.selected = this.selectedTab
    }
}
</script>
<template>
    <div class="tabs" :class="`tabs-${theme}`">
        <div class="tabs-menu">
            <button class="tabs-menu-item" :class="{'tabs-menu-item-active': index == selected}" data-tab="tab1" v-for="(tab, index) in tabs" :key="`tab1-${index}`" @click="select(index)">
                {{ tab.title }}
            </button>
        </div>
        <div class="tabs-contents">
            <div v-for="(tab, index) in tabs" :key="`tabc1-${index}`">
                <component v-show="index == selected" :is="tab.component"></component>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.tabs{
    &-normal{
        .tabs{
            &-menu{
                padding: 0 1.2rem 0;
                border-bottom: 2px solid #ececec;
                display: flex;
                &-item{
                    padding: 0.5rem 1rem;
                    font-weight: 500;
                    color: #0663ce;
                    border: 2px solid transparent;
                    margin-bottom: -2px;
                    &.tabs-menu-item-active{
                        color: #949494;
                        background: #fff;
                        border: 2px solid #ececec;
                        border-bottom: 2px solid #fff;
                        border-radius: 0.5rem 0.5rem 0 0;
                        margin-bottom: -2px;
                    }
                }
            }
        }
    }
    &-material{
        .tabs{
            &-menu{
                padding: 0 1.2rem 0;
                border-bottom: 2px solid #ececec;
                display: flex;
                &-item{
                    padding: 0.5rem 1rem;
                    font-weight: 500;
                    color: #0663ce;
                    border: 2px solid transparent;
                    margin-bottom: -2px;
                    &.tabs-menu-item-active{
                        color: #949494;
                        background: #fff;
                        border: 2px solid transparent;
                        border-bottom: 3px solid #949494;
                        border-radius: 0.5rem 0.5rem 0 0;
                        margin-bottom: -2px;
                    }
                }
            }
        }
    }
}
</style>