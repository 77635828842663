
<script>
import RiModal from '@/components/RI/Modal.vue'
import RIHeader from '@/components/RI/HeaderComp.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
           products: [
                {
                    no_cuenta: '0074081200117400527',
                    tipo_de_participacion: 'TITULAR', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
                {
                    no_cuenta: '0074027000159854312',
                    tipo_de_participacion: 'FISICA USUARIO BCA ELECTRONICA', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
                {
                    no_cuenta: '0074082700159857001',
                    tipo_de_participacion: 'FISICA USUARIO BCA ELECTRONICA', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
                {
                    no_cuenta: '007482700159858806',
                    tipo_de_participacion: 'FISICA USUARIO BCA ELECTRONICA', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
                {
                    no_cuenta: '00740827000189518886',
                    tipo_de_participacion: 'TITULAR', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
                {
                    no_cuenta: '00743465000444079396',
                    tipo_de_participacion: 'TITULAR', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
                {
                    no_cuenta: '00743465000444079418',
                    tipo_de_participacion: 'TITULAR', 
                    producto_servicio_asociado: 'CHEQUES', 
                },
           ]
        }
    },
    computed: {
        ...mapGetters({
            clientsCurrent: 'clients/current',
            'loadingStatus': 'loading/status',
            'loadingMessage': 'loading/message'
        })
    },
    components: {
        RiModal,
        RIHeader
    },

    methods: {
        showResults() {
            if(this.results.length) {
                this.$refs.modalSearchResults.open()
            }
        },

        gotToRating() {
            this.loadingSetStatus(true)
            this.loadingSetMessage('Procesando tu petición')
            this.$router.push({name: 'RiRating', params: {id: this.$route.params.id}})
        },

        selectClient(client) {
            this.loadingSetStatus(true)
            this.loadingSetMessage('Procesando tu petición')
            this.$refs.modalSearchResults.close()
            this.clientsSetCurrent(client)
        },
        
        ...mapMutations({
            'loadingSetStatus': 'loading/setStatus',
            'loadingSetMessage': 'loading/setMessage',
            'clientsSetCurrent': 'clients/setCurrent',
        })
    },

    mounted() {
        this.clientsSetCurrent(this.$route.params.id)
        setTimeout(() => {
            this.loadingSetStatus(false)
        }, 1000)
    }


}
</script>
<template>
    <div>
        <RIHeader @search="showResults"/>
        <div class="header__light-blue flex items-center">
            <ul class="flex text-sm items-center ul-menu">
                <li class="px-2">Operación de Cuentas <i class="fas fa-sort-down text-white"></i></li>
                <li class="px-2">Servicios <i class="fas fa-sort-down text-white"></i>
                    <ul>
                        <li class="p-2">Verificaciones</li>
                        <li class="p-2">Aclaraciones <i class="fas fa-caret-right text-black"></i></li>
                        <li class="p-2">Estado de Cuenta <i class="fas fa-caret-right text-black"></i>
                            <ul>
                                <li class="p-2">Inscribir Impresión de Cuenta</li>
                            </ul>
                        </li>
                        <li class="p-2">Solicitud Derechos ARCO</li>
                        <li class="p-2">Mantenimiento TIB</li>
                        <li class="p-2">Mantenimiento Canales <i class="fas fa-caret-right text-black"></i></li>
                        <li class="p-2">Asociar Teléfono Celular</li>
                        <li class="p-2">Cartas PDV <i class="fas fa-caret-right text-black"></i></li>
                        <li class="p-2">Constancias SAT</li>
                        <li class="p-2">Rating <i class="fas fa-caret-right text-black"></i>
                            <ul>
                                <li class="p-2" @click="gotToRating">Tomas de Información</li>
                                <li class="p-2">Consulta</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="px-2">Análisis <i class="fas fa-sort-down text-white"></i></li>
                <li class="px-2">Desembolso <i class="fas fa-sort-down text-white"></i></li>
                <li class="px-2">Seguimiento <i class="fas fa-sort-down text-white"></i></li>
                <li>Modificar</li>
            </ul>
        </div>
        <div class="header__dark-blue flex ">
            <ul class="flex text-sm items-center ul-menu">
                <li class="px-2 font-bold">Accesos Rápidos:</li>
                <li class="px-2">Modificar Datos Cliente</li>
                <li class="px-2">Consultar BNC</li>
                <li class="px-2">Validación INE</li>
            </ul>
        </div>
        <div class="content py-2 px-1">

            <div class="section__header section__header--blue">
                <h2 class="text-white">Posición</h2>
            </div>


            <div class="px-3  mt-5 ">
                <div class="border">
                    <div class="section__header">
                        <h2>Datos Generales</h2>
                    </div>
                    <div class="flex justify-center py-1">
                        <div class="px-3">
                            Número de Cliente: {{ clientsCurrent.numero_cliente }}
                        </div>
                        <div class="px-3">
                            Sector: {{ clientsCurrent.sector }}
                        </div>
                        <div class="px-3">
                            Segmento: {{ clientsCurrent.segmento }}
                        </div>
                        <div class="px-3">
                            <span class="px-2">
                                Solicitado 
                                <input type="checkbox" :checked="clientsCurrent.solcitado">
                            </span>
                            <span class="px-2">
                                No Solicitado 
                                <input type="checkbox" :checked="!clientsCurrent.solcitado">
                            </span>
                            <span class="px-2">
                                Exacto
                                <input type="checkbox" :checked="clientsCurrent.exacto">
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="flex flex-wrap px-3 py-2">
                <div class="w-1/2 px-3  mt-5 ">
                    <div class="border">
                        <div class="section__header">
                            <h2>Productos y Servicios</h2>
                        </div>
                        <div class="overflow-x-auto overflow-y-auto	w-full h-72	">
                            <table class="table-blue">
                                <thead>
                                    <tr>
                                        <th>No. Cuenta</th>
                                        <th>Tipo de participación</th>
                                        <th>Producto/Servicio Asociado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, index) in products" :key="`prod-${index}`">
                                        <td>{{ product.no_cuenta }}</td>
                                        <td>{{ product.tipo_de_participacion }}</td>
                                        <td>{{ product.producto_servicio_asociado }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="w-1/2 px-3  mt-5">
                    <div class="border ">
                        <div class="section__header">
                            <h2>Otros Mantenimientos</h2>
                        </div>
                        <div class="h-72 overflow-x-auto overflow-y-auto">
                            <p class="text-sky-900	font-bold text-center">Canales</p>
                        </div>
                    </div>
                </div>
               
            </div>

            <div class="px-2">
                <ul class="tabs">
                    <li class="tab tab--active">Expediente Actual</li> 
                    <li class="tab">Documentos por Vencer</li>
                    <li class="tab">Documentos Vencidos</li>
                </ul>

                <div class="border">
                    <div class="h-5 bg-light-blue">

                    </div>
                    <div class="h-64">
                        <table class="table-blue w-full">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Nombre</th>
                                    <th>Cuenta</th>
                                    <th>Participación</th>
                                    <th>Descripción</th>
                                    <th>Productos/Servicio</th>
                                    <th>Subproducto</th>
                                    <th>Referencia</th>
                                    <th>Fecha Alta</th>
                                    <th>Estado Digital</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ri-modal ref="modalWelcome" size="lg:w-2/5 md:w-8/12">
            <template v-slot:body>
                <div class="info-modal">
                    <div class="info-modal__side">
                        <div>
                            <img src="/imgs/info.png" alt="">
                        </div>
                        <p class="mt-5">
                            Información
                        </p>
                    </div>
                    <div class="px-3 py-1 info-modal__content">
                        <h2 class="info-modal__title">Te damos la bienvenida a Escenarios Comerciales</h2>

                        <p class="mt-24">Te encuentras en la sucursal</p>
                        <p class="mt-0 font-bold">OFICINA</p>

                        <div class="info-modal__fotter px-3 py-5">
                            <button class="btn btn-green font-bold px-5" @click="$refs.modalWelcome.close">Cerrar</button>
                        </div>
                    </div>
                </div>
            </template>
        </ri-modal>
        <ri-modal ref="modalSearchResults" size=" md:w-8/12">
            <template v-slot:body>
                <div class="search-results px-2">
                    <div class="search-results__header text-right py-2">
                        <button class="text-blue" @click="$refs.modalSearchResults.close"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="search-results__info">
                        <img src="/imgs/info.png" alt="">
                        <p>NO EXISTEN MAS PERSONAS</p>
                    </div>
                    <div class="search-results__content">
                        <table class="search-results__table">
                            <thead>
                                <tr>
                                    <th class="text-left">M</th>
                                    <th class="text-left">Nombres(s)</th>
                                    <th class="text-left">Apellido Paterno</th>
                                    <th class="text-left">Apellido Materno</th>
                                    <th class="text-left">RFC</th>
                                    <th class="text-left">Homoclave</th>
                                    <th class="text-left"># Cliente</th>
                                    <th class="text-left">Domicilio Básico</th>
                                    <th class="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(client, index) in results"  :key="`results-${index}`">
                                    <td class="text-left">C</td>
                                    <td class="text-left">{{ client.nombre }}</td>
                                    <td class="text-left">{{ client.tipo }}</td>
                                    <td class="text-left"></td>
                                    <td class="text-left">{{ client.rfc }}</td>
                                    <td class="text-left">{{ client.homoclave }}</td>
                                    <td class="text-left">{{ client.numero_cliente }}</td>
                                    <td class="text-left">{{ client.domiciolio_basico }}</td>
                                    <td class="text-center"><button class="btn btn-primary" @click="selectClient(client)"><i class="fas fa-user"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="search-results__controls py-3">
                        <div>
                            <button class="btn btn-blue mr-1">Cliente</button>
                            <button class="btn btn-blue">Cliente/Prospecto</button>
                        </div>
                        <div class="ms-auto">
                            <button class="btn mr-1" disabled>Inicio</button>
                            <button class="btn mr-1" disabled>Anterior</button>
                            <button class="btn mr-1" disabled>Siguiente</button>
                        </div>
                    </div>
                    <div class="text-right pb-3">
                        <button class="btn btn-green font-bold" @click="$refs.modalSearchResults.close">Cerrar</button>
                    </div>
                </div>
            </template>
        </ri-modal>
    </div>
</template>
<style lang="scss" scoped>

.search-results{
    &__header{
        height: 2rem;
    }
    &__info{
        display: flex;
        align-items: center;
        color: #004580;
        font-weight: bold;
        border: 1px solid #004580;
        margin-bottom: 2rem;
        img{
            width: 35px;
        }
    }
    &__content{
        height: 40vh;
    }
    &__table{
        width: 100%;
        thead{
            tr{
                th{
                    color: #818181;
                }
            }
        }
        tbody{
            tr{
                td{
                    background-color: #efefef;
                    color: #1b1b1b;
                }
            }
        }
    }
    &__controls{
        display: flex;
    }
}
.form_search_client{
    select,
    input{
        font-size: 0.8rem;
        height: 25px;
        border: 1px solid #ccc;
    }
}
.table-home{
    tr{
        td{
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }
    }
    &__title{
        color: #056fb6;
        font-weight: bold;
    }
}

.home-tabs{
    display: flex;
    .home-tab{
        background-color:#004580;
        color: #fff;
        margin-right: 0.25rem;
        padding: 0.25rem 1rem;
        border-radius: 0.25rem 0.25rem  0 0;
        min-width: 120px;
        &.home-tab--active{
            background-color: #b0cbe2;
            font-weight: bold;
        }
    }
}

.header__client{
    border: 2px solid #004580;
    width: 35%;
    background-color: #f1f1f1;
    padding: 0.1rem 0.25rem;
    &-menu{
        display: flex;
        color: #004580;
        font-size: 0.9rem;
        & > li{
            border-right: 1px solid #ccc;
            position: relative;
            line-height: 0.9rem;
            cursor: pointer;
            padding: 0 0.2rem;
            & > ul {
                position: absolute;
                background: #fff;
                white-space: nowrap;
                left: 0;
                top: 100%;
                box-shadow:  2px 0 0 rgba($color: #000000, $alpha: 0.3);
                display: none;
                z-index: 99;
                &> li {
                    padding: 0.1rem 0.2rem;
                    line-height: 0.8rem;
                    font-weight: 600;
                    cursor: pointer;
                    p{
                        margin: 0;
                        line-height: 0.9rem;
                    }
                    &:hover{
                        background-color: #6097c6;
                        color: #fff;
                    }
                }
            }
            &:hover{
                & > ul {
                    display: block;
                }
            }
        }
    }
}
.header__home{
    display: flex;
    &-l{
        width: 65%;
        &-bottom,
        &-top{
            display: flex;
        }
    }
    &-logo{
        width: 70%;
        display: flex;
        white-space: nowrap;
        align-items: flex-end;
        font-size: 1.2rem;
        color: #004580;
        img{
            width: 30%;
        }
    }
    &-actions{
        background-color: #ececec;
        border: 1px solid #d3d2d2;
        color: #004580;
        align-self: flex-start;
        padding: 0.25rem 0.5rem;
        margin-top: 0.5rem;
        font-size: 0.9rem;
    }
}
.info-modal{
    display: flex;
    height: 350px;
    &__side{
        width: 30%;
        background-color: #e6f2fb;
        height: 100%;
        text-align: center;
        img{
            width: 80px;
            display: inline-block;
        }
    }
    &__title{
        color: #056fb6;
        font-weight: 600;
        font-size: 1.1rem;
    }
    &__content{
        position: relative;
        width: 80%;
    }
    &__fotter{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.header{
    &__light-blue{
        background-color: #b0cbe2;
        height: 2rem;
    }
    &__dark-blue{
        background-color: #6395c4;
        height: 2rem;
    }
}
</style>