<script>
import RiLoading from '@/components/RI/Loading.vue'

export default {
    components: {
        RiLoading
    },
    computed: {
        menuDynamic() {
            return this.$route.meta.menu || []
        }
    },
    methods: {
        subMenuActive(item) {
            let active = false
            item.submenu.forEach(element => {
                if(!active) {
                    active = element.route == this.$route.name
                }
            });

            return active
        }
    }

}
</script>
<template>
    <div class="main">
        <div class="main__content">
            <header class="main__header p-2">
                <div class="font-bold">
                    <span>
                        <i class="fas fa-chevron-down"></i>
                    </span>
                    <span>
                        Mi sesión
                    </span>
                </div>
                <div class="mx-auto font-bold">
                    <span>NOMBRE ASESOR</span>
                </div>
                
                <div>
                    <span>Salir</span>
                </div>
            </header>
            <router-view></router-view>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.main{
    width: 100vw;
    min-height: 100vh;
    background-color: #75a5c7;
    &__content{
        max-width: 1200px;
        margin: 0 auto;
        background-color: #fff;
    }
    &__header{
        display: flex;
        background: #e7e9e7;
        align-items: center;
        font-size: 0.8rem;
    }
}
</style>
