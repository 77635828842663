<script>
import RiLoading from '@/components/RI/Loading.vue'

export default {
  components: {
    RiLoading
  },
  computed: {
    layout() {
      return this.$route.meta?.layout || 'div'
    }
  }
}
</script>
<template>
  <div>
    <component :is="layout" id="app">
      <router-view/>
    </component>
    <RiLoading/>
  </div>
</template>

<style lang="scss">
@import './styles/app.scss';
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
