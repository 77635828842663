<script>
import AccordionVue from '@/components/CreditLens/AccordionVue.vue'
export default {
    components: {
        AccordionVue
    }
}
</script>
<template>
    <div class="p-4">
        <AccordionVue class="mb-6">
            <template v-slot:header>
                <h1 class="text-lg">Notificaiones</h1>
            </template>
        </AccordionVue>
        <AccordionVue class="mb-6">
            <template v-slot:header>
                <h1 class="text-lg">Detalles del cliente</h1>
            </template>
        </AccordionVue>
        <AccordionVue class="mb-6">
            <template v-slot:header>
                <h1 class="text-lg">Detalles de contacto</h1>
            </template>
        </AccordionVue>
        <AccordionVue class="mb-6">
            <template v-slot:header>
                <h1 class="text-lg">Domicilio</h1>
            </template>
        </AccordionVue>
        <AccordionVue class="mb-6">
            <template v-slot:header>
                <h1 class="text-lg">Grupo de entidades</h1>
            </template>
        </AccordionVue>
    </div>
</template>