<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            client: null
        }
    },
    computed: {
        ...mapGetters({
            'clientsFind' : 'clients/find',
            'clientsCurrent' : 'clients/current'
        })
    },
    methods: {
        ...mapMutations({
            'clientsSetCurrent':'clients/setCurrent'
        })
    },  
    mounted() {
        let bbvaId = this.$route.params.id

        if(!bbvaId) {
            this.$router.push({name: 'HomeView'})
        }

        this.clientsSetCurrent(bbvaId)

        let client = this.clientsCurrent.client

        if(!client) {
            this.$router.push({name: 'HomeView'})
        }

        this.$set(this, 'client', client)
    }
}
</script>
<template>
    <div class="app-main-content" v-if="client">
        <div class="section-header" >
            <div>
                <span>
                    <i class="fas fa-sitemap"></i>
                </span>
                <span class="ml-2">{{ client.nombre_largo }} <span><i class="fas fa-angle-down"></i></span> </span>
            </div>
            <div class="ml-auto">
                <span><i class="far fa-file-alt"></i></span>
                <span class="ml-1 text-sm">Informes</span>
            </div>
        </div>
        <div class="section-content">
            <router-view :client="client"></router-view>
        </div>
        <div class="section-footer p-4 border" v-if="!['CreditLensDetailsAnlysis'].includes($route.name)">
            <button class="button">Guardar</button>
            <button class="button text-blue-primary mx-2">Guardar como borrador</button>
            <button class="button button-link">Cancelar</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.app-main-content{
    display: flex;
    flex-direction: column;
    max-height: 100%;
}
.section-content{
    flex-grow: 1;
    height: 50%;
    overflow: auto;
}
</style>