<script>
import AccordionVueVue from '../../AccordionVue.vue'
import TabsVue from '../../TabsVue.vue';
import BasicForm from '@/components/CreditLens/Details/IdentityInformation/InformationForms/BasicForm.vue'
import ClimateESGFrom from '@/components/CreditLens/Details/IdentityInformation/InformationForms/ClimateESGForm.vue'
import HistoryForm from '@/components/CreditLens/Details/IdentityInformation/InformationForms/HistoryForm.vue'

export default {
    components: {
        AccordionVueVue,
        TabsVue,
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Detalles básicos',
                    component: BasicForm
                },
                {
                    title: 'Climate & ESG',
                    component: ClimateESGFrom
                },
                {
                    title: 'Historial de reorganización',
                    component: HistoryForm
                },
            ]
        } 
    },
}
</script>
<template>
   <div class="py-2">
        <TabsVue :tabs="tabs" :theme="`material`"></TabsVue>
   </div>
</template>