<script>
import { mapMutations } from 'vuex'
export default {
    props: {
        item: {
            type: Object,
            require() {
                return true
            }
        }
    },
    data() {
        return {
            optionSelected: '',
            change: false
        }
    },

    watch: {
        optionSelected(nVal) {
            this.change = true
        }
    },  
  
    methods: {
        save() {
            this.$emit('save', this.optionSelected)
            this.$emit('close')
        },  
        ...mapMutations({
            clientsNewExercise: 'clients/newExercise'
        })
    }
}
</script>
<template>
    <div class="ex-modal__wrap">
        <div class="ex-modal__header">
            <h1>Rating Información Cualitativa Cuestionario</h1>
            <button class="ex-modal__header-close" @click="$emit('close')"><i class="fas fa-times"></i></button>
        </div>

        <div class="ex-modal__body">
            <div class="mt-4 px-3">
                <div class="border">
                    <div class="section__header">
                        <h2>Preguntas y Respuestas</h2>
                    </div>
                    <div>
                       <table class="table table-blue w-full">
                            <tbody>
                                <tr v-for="(option, index) in item.options" :key="`option-${index}`">
                                    <td>
                                        <label class="flex items-center w-full">
                                            <input type="radio" name="option-qualitative" :value="option" v-model="optionSelected"> 
                                            <span class="ml-2">
                                                {{ option }}
                                            </span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                       </table>
                    </div>
                </div>
                <div class="flex">
                    <button class="w-2/12 btn btn-green  font-bold mx-1" :disabled="!change" @click="save">Aceptar</button>
                    <button class="w-2/12 btn btn-green  font-bold mx-1"  @click="$emit('close')">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
$modal_color: #0078ff;
.ex-modal{
    &__wrap{
        border: 2px solid $modal_color;
        min-height: 50vh;
    }
    &__header{
        background: $modal_color;
        color: #fff;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 0.25rem;
        position: relative;
        &-close{
            position: absolute;
            right: 1rem;
            top: 0.25rem;
            background: #0040a6;
            width: 1rem;
            height: 1rem;
            padding: 0;
            line-height: 1rem;
        }
    }
}
</style>