import Vue from 'vue'
import VueRouter from 'vue-router'
import CreditLensDetails from '@/views/CreditLends/DetailsView.vue'
import CreditLensDetailsIndex from '@/views/CreditLends/Detailts/Index.vue'
import CreditLensDetailsAnalysis from '@/views/CreditLends/Detailts/Analysis.vue'
// RI
import RiLonging from '@/views/RI/LoginView.vue'
import RiAfterLogin from '@/views/RI/AfterLogin.vue'
import RiHome from '@/views/RI/HomeView.vue'
import RiOperation from '@/views/RI/OperationView.vue'
import RiRating from '@/views/RI/RatingView.vue'

import CreditLensMenu from './creditlens'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'RiLonging',
    component: RiLonging,
    meta: {
      layout: ''
    }
  },
  {
    path: '/loading',
    name: 'RiAfterLogin',
    component: RiAfterLogin,
    meta: {
      layout: ''
    }
  },
  {
    path: '/home',
    name: 'RiHome',
    component: RiHome,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/operation/:id',
    name: 'RiOperation',
    component: RiOperation,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/operation/:id/rating',
    name: 'RiRating',
    component: RiRating,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/details/:id',
    name: 'CreditLensDetails',
    component: CreditLensDetails,
    meta: {
      layout: 'MainLayout'
    },
    children: [
      {
        path: '/details/:id',
        name: 'CreditLensDetailsIndex',
        component: CreditLensDetailsIndex,
        meta: {
          layout: 'MainLayout',
          menu: CreditLensMenu,
        },
      },
      {
        path: '/details/:id/analysis',
        name: 'CreditLensDetailsAnlysis',
        component: CreditLensDetailsAnalysis,
        meta: {
          layout: 'MainLayout',
          menu: CreditLensMenu,
        },
      },

    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '*',
    redirect: { name: 'RiHome' } 
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
