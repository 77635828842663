import clients from "@/data/clients"
import periods from "@/data/periods"
import config from "@/data/config" 
import types from '@/data/typesClients'

let preloadClients = localStorage.getItem('clients')

if(preloadClients) {
    preloadClients = JSON.parse(preloadClients)
}
console.log('LOCAL', preloadClients)
export default () => {
    return {
        current: null,
        staging: {
            client: null,
            periods: null
        },
        clients: preloadClients || clients,
        periods: periods,
        config: config,
        types: types,
        searchParams: {}
    }
}
