<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            form: '',
            formSearch: {
                type: '',
                name: '',
            },
        }
    },
    computed: {
        ...mapGetters({
            types: 'clients/types',
            results: 'clients/results',
            clientsCurrent: 'clients/current'
        })
    },
    methods: {
        showForm(form) {
            this.form = form
        },  

        search() {
            let params = {
                tipo: this.formSearch.type,
                nombre: this.formSearch.name,
            }

            this.clientSetSearchParams(params)

            this.$emit('search')
        },
        ...mapMutations({
            clientSetSearchParams: 'clients/setSearchParams'
        })
    }
}
</script>
<template>
    <div class="header__home">
        <div class="header__home-l mt-auto">
            <div class="header__home-l-top">
                <div class="header__home-logo px-3">
                    <img src="/imgs/BBVA_2019.svg" alt=""> <span>Banca de Empresas y Gobierno</span>
                </div>
                <div class="header__home-actions">
                    <span>
                        Mis acciones
                        <span>
                            <i class="fas fa-caret-down"></i>
                        </span>
                    </span>
                </div>
            </div>
            <div class="header__home-l-bottom mt-1">
                <div class="home-tabs">
                    <button class="home-tab" :class="{'home-tab--active': $route.name  == 'RiHome'}">
                        Hoy
                    </button>
                    <button class="home-tab">
                        Mis clientes
                    </button>
                    <button class="home-tab" :class="{'home-tab--active': $route.name  == 'RiOperation'}">
                        Mi Operación
                    </button>
                    <button class="home-tab">
                        Mi Gestión
                    </button>
                </div>
                <div class="ml-auto px-5">
                    <button class="btn btn-red"><i class="fas fa-search"></i> Ayuda CUC</button>
                </div>
            </div>
        </div>
        <div class="header__client">
                <ul class="header__client-menu">
                    <li>

                        <p>Cliente <i class="fas fa-caret-down"></i></p>
                        <ul>
                            <li>Nombre</li>
                            <li>Razón Social</li>
                            <li>RFC</li>
                            <li @click="showForm('reasonSocial')"># Cliente</li>
                        </ul>
                    </li>
                    <li>
                        <p>Producto  <i class="fas fa-caret-down"></i></p>
                    </li>
                    <li>
                        <p>Gestión  <i class="fas fa-caret-down"></i></p>
                    </li>
                </ul>
                <div class="py-3" v-if="clientsCurrent">
                    <p class="font-bold text-center">{{ clientsCurrent.numero_cliente }}--{{ clientsCurrent.nombre }} {{ clientsCurrent.tipo }}</p>
                </div>
                <div class="py-3" v-else-if="!form">
                    <p class="font-bold text-center">No hay Cliente Seleccionado</p>
                </div>
                <div class="flex form_search_client py-3" v-else-if="form == 'reasonSocial'">
                    <select class="w-1/2" name="" id="" v-model="formSearch.type">
                        <option value="" disabled style="display: none;">--Seleccione--</option>
                        <option v-for="(type, indx) in types" :key="indx + '-type'" :value="type" >{{ type }}</option>
                    </select>
                    <input class="w-1/2" type="text" v-model="formSearch.name">

                    <button class="btn btn-primary ms-1" @click="search"><i class="fas fa-search"></i></button>
                </div>
                <ul class="header__client-menu">
                    <li>
                        <p>Contratación</p>
                    </li>
                    <li>
                        <p>Ficha Cliente</p>
                    </li>
                    <li>
                        <p>Operar</p>
                    </li>
                </ul>
        </div>
    </div>
</template>