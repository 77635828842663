export const results = (state) => {
    let params = state.searchParams
    console.log(params)
    return state.clients.filter(client => {
        let keys = Object.keys(params)
        let match = keys.length 
        
        
        keys.forEach(key => {
            if(match) {
                let value = client[key] ? client[key].toLowerCase() : ''
                let query = params[key] ? params[key].toLowerCase() : ''
                if(!query) {
                    match = false
                } else {
                    match = value.includes(query)
                }
            }
        })

        return match
    })
}

export const find = (state) => (bbvaID) => {
    return state.clients.find(client => client.bbvaid == bbvaID)
}

export const current = (state) => state.clients.find(c => c.id == state.current)

export const staging = (state) => state.staging

export const config = (state) => state.config
export const types = (state) => state.types