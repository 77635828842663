<script>
export default {
    data() {
        return {}
    },
    mounted() {
        // Definir el contenido del PDF
        // var docDefinition = {
        // content: 'Contenido del PDF',
        // userPassword: 'contraseña', // Establecer la contraseña del usuario
        // ownerPassword: 'contraseña_de_admin', // Establecer la contraseña del propietario
        // };

        // // Generar el PDF y descargarlo
        // pdfMake.createPdf(docDefinition).download('archivo.pdf');
    }
}
</script>
<template>
    <div>
        <header class="header p-2">
            <img class="header__logo" src="/imgs/logo_bancomer.png" alt="">
            <h4 class="header__title ms-auto">
                BEyG - vía 2
            </h4>
        </header>
        <div class="spacer-h"></div>
        <section class="login__main">
            <div class="login__form">
                <div class="login__header">
                    <div>
                        <span><i class="fas fa-lock"></i></span>
                        <span>Longin</span>
                    </div>
                    <div class="ms-auto">
                        <span>Bienvenido</span>
                    </div>
                </div>
                <form action="">
                    <div class="my-3">
                        <input class="app-input" placeholder="Usuario" type="text">
                    </div>
                    <div class="my-3">
                        <input class="app-input" placeholder="Contraseña" type="password">
                    </div>
                    <div>
                        <button class="btn btn-primary w-full" @click="$router.push({name: 'RiAfterLogin'})">Entrar</button>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template> 
<style lang="scss" scoped>
.btn{
    font-size: 1.2rem;
}
.app-input{
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    height: 36px;
    width: 100%;
    padding: 0.25rem;
    font-size: 1.1rem;
}
.header{
    display: flex;
    background: #fff;
    align-items: center;
    &__logo{
        max-width: 25%;
        max-height: 50px;
    }
    &__title{
        font-size: 4rem;
        color: #00a0ea;
    }
}
.spacer-h{
    height: 4rem;
    background: #fff;
}
.login{
    &__main{
        background-image: url('/public/imgs/bg_login.png');
        min-height: 300px;
        background-size: auto 100%;
        display: flex;
        align-items: center;
    }
    &__form{
        margin: auto;
        display: block;
        width: 80%;
        max-width: 380px;
        margin: 0 auto;
        border: 1px solid #7c898d;
        background-color: #f1fdfa;
        padding: 0.75rem;
        border-radius: 1rem;
        padding-bottom: 2rem;
    }
    &__header{
        display: flex;
        font-size: 1.2rem;
        color: #056fb6;
    }
}
</style>