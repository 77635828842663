import Vue from 'vue'
import VTooltip from 'v-tooltip'

import App from './App.vue'
import router from './router'
import store from './store'
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'

Vue.config.productionTip = false

Vue.use(require('vue-moment'));

Vue.component('SimpleLayout', SimpleLayout)

Vue.component('MainLayout', MainLayout)

Vue.use(VTooltip)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
