<script>
import { mapMutations } from 'vuex'
export default {
    data() {
        return {
            form: {
                desde: '',
                hasta: '',
                segmento: '',
                en: 'MILES',
                tribulacion: 'REGIMEN GENERAL',
                estructura_prod: 'HIL. Y TEJ. FIB. BLANDAS',
                certificacion: 'AUDITADA S/ SALVEDADES',
                estado: 'CIUDAD DE MEXICO',
                num_empleados: '300',
                nombre: '',
                procedencia: '',
                salvedades: '',
                descripcion_de_salvedades: ''

            },
            lists: {
                en: [
                    'INIDADES',
                    'MILES',
                    'MILLONES',
                    'CIEN MIL'
                ],
                tribulacion: [
                    'CARGA INICIAL',
                    'NO APLICA',
                    'REGIMEN GENERAL',
                    'REGIMEN SIMPLIFICADO'
                ],
                estructura_prod: [
                    'SIN DESCRIPCION / NO APLICA',
                    'ABARROTES Y BEBIDAS',
                    'ABONOS Y FERTILIZANTES',
                    'ACEITES Y GRASES COMESTIBLES',
                    'ACUACULTURA',
                    'AGENCIAS DE PUBLICDAD',
                    'AGRICULTURA',
                    'AGUA',
                    'ALAMBRE Y ART DE ALAMBRE',
                    'ALFOMBRAS, TAPETES Y SIM',
                    'ALIMENTOS PARA ANIMALES',
                    'ALMACENADORAS',
                    'APARATOS ELECTRODOM.',
                    'APARATOS ELECTRICOS',
                    'ARBOLES FRUTALES',
                    'ARRENDADORAS',
                    'ARTICULOS DE PLASTICO',
                    'ASEGURADORAS', 
                    'AVICULTURA',
                    'BANCA COMERCIAL',
                    'BEBIDAS ALCOHOLICAS',
                    'C-V DE MUEBLES Y ELECTRODOMESTICOS',
                    'C-V DE ROPA Y CALZADO',
                    'C-V INSUMOS PARA LA INDUSTRIA',
                    'C-V MAT. P-CONTRUCCION',
                    'CARGA, EST Y ADMON PORTURARIA',
                    'CARNES Y LACTEOS',
                    'COMP. PERSONALES',
                    'COMUNICACIONES',
                    'CONST Y CONC DE CARRETERAS',
                    'CONSTRUCCION',
                    'CUERO Y CALZADO',
                    'CULTIVO DE MAIZ',
                    'CULTIVO DE TRIGO',
                    'CULTIVOS BASICOS',
                    'DERIVADOS DE MAIZ',
                    'DISCOS, JUGUESTES Y REGALOS',
                    'DIST DE AUTOMOVILES',
                    'EDIFICACION NO RESID',
                    'EDUCACION',
                    'ELABORACION DE CERVEZA',
                    'ELECTRICIDAD',
                    'ELECTRICIDAD, GAS Y AGUA',
                    'EMPACADO DE CARNE',
                    'ENSAMB. DE AUTOMOVILES',
                    'ENSERES ELECDOM. MAYS',
                    'ENSERES ELECDOM. MENS',
                    'ENV DE HOJALATA',
                    'ENV DE PAPEL Y CARTON',
                    'ENV DE PLASTICO',
                    'ENV DE VIDRIO',
                    'EQUIP Y MAT DE TRANSPORTE',
                    'EQUIP. Y APARATOS ELECTRICOS',
                    'EQUIP. Y APARATOS ELECTRONICOS',
                    'ESPARCIMIENTO',
                    'ESTACIONES DE GASOLINERA',
                    'EXPL. CANT ARENA, GRAVA Y A.',
                    'EXTR. DE CARBON Y GRAFITO',
                    'EXTR. DE MIN. NO METAL',
                    'EXTR. PETROLEO Y GAS NATURAL',
                    'EXTR. Y BEN. DE COBRE',
                    'EXTR. Y BEN. DE HIERRO',
                    'EXTR. Y BEN. DE PLATA',
                    'EXTR. Y BEN. DE ZINC',
                    'FAB. DE CALZADO',
                    'FAB. DE AUTOPARTES',
                    'FACTORAJE',
                    'FARMACIAS',
                    'FERRETERIA Y TLAPALERIAS',
                    'FINANCIERAS AUTOMOTRICES',
                    'GANADERIA',
                    'GANADO BOVINO-CARNE',
                    'GANADO BOVINO-LECHE',
                    'GANADO PORCINO',
                    'GASERAS',
                    'GOB. EDO DE COLIMA',
                    'GOB. EDO DE CHIAPAS',
                    'GOB. EDO DE DURANGO',
                    'GOB. EDO DE GUANAJUATO',
                    'GOB. EDO DE JALISCO',
                    'GOB. EDO DE MEXICO',
                    'GOB. EDO DE MOCHOACAN',
                    'GOB. EDO DE NUEVO ELON',
                    'GOB. EDO DE OAXACA',
                    'GOB. EDO DE SONORA',
                    'GOB. EDO DE SLP',
                    'GOBIERNO DEL D.F.',
                    'HIERRO Y ACERO',
                    'HIL. Y TEJ. FIB. BLANDAS',
                    'HIL. Y TEJ. FIB. ART.',
                    'HIL. Y TEJ. FIB. DURAS',
                    'HILADOS Y TEJ. ALGODON',
                    'HILOS E HDOS COSER Y TEJ.',
                    'HORTALIZAS',
                    'HOTELES 4/5 EST. EN CIUDAD',
                    'HOTELES 4/5 EST PLAYA',
                    'IMPRENTA Y EDITORIALES',
                    'IND. BASICAS DE MET NO FE',
                    'INDUSTRIA AZUCARERA',
                    'INFORMATICA',
                    'JABONES, DET. Y COSMETICOS',
                    'LAMINADOS NO PLANOS',
                    'LAMINADOS PLANOS',
                    'MAQ Y APARATOS ELECTRICOS',
                    'MAQ Y APARATOS NO ELECTRICOS',
                    'MAQUILADORAS',
                    'MEDICAMENTOS, EQ MEDICO, LABS',
                    'MINERALES NO METALICOS',
                    'MOLIENDA DE CAFE',
                    'MOLIENDA DE NIXTAMAL',
                    'MOLIENDA DE TRIGO',
                    'MOLIENDA DE MADERA',
                    'MUEBLES METALICOS',
                    'O IND MANUFACT',
                    'O INDUSTRIAS TEXTILES',
                    'O PROD QUIMICOS',
                    'O PROD ALIMENTICIOS',
                    'O SERVS DE ESPARCIMIENTO',
                    'OBRA PUBLICA',
                    'OTROS AGENTES Y SERVS FIN.',
                    'PAPEL Y CARTON',
                    'PAPELERIAS Y LIBRERIAS',
                    'PASTA Y CELULOSA',
                    'PETROLEO Y DERIVADOS',
                    'PETROQUIMICA',
                    'PRENDAS DE VESTIR',
                    'PREP. DE FRUTAS Y LEGUMBRES',
                    'PROD. FARMACEUTICOS',
                    'PROD. MET ESTRUCTURALES',
                    'PRODUCTOS DE HULE',
                    'PRODUCTOS LACTEOS',
                    'QUIMICA BASICA',
                    'RADIOLOCALIZACION',
                    'REFRACCIONES P/ VEHICULOS',
                    'REFRESCOS Y AGUSAS GASEOSAS',
                    'RENTA DE INMUEBLES',
                    'RENTA DE LOCALES COMERCIALES',
                    'RENTA DE MAQUINARIA',
                    'RENTA DE NAVES IND Y COMER',
                    'RENTA DE OFICINAS',
                    'RESINAS SINT Y FIB ART',
                    'RESTAURANTES',
                    'RESTAURANTES Y HOTELES',
                    'SERVICIOS FINANCIEROS',
                    'SERVICIOS MEDICOS',
                    'SERVICIOS PROFESIONALES',
                    'SERVICULTURA',
                    'SOFOLES HIPOTECARIAS',
                    'SUPERMERCADOS',
                    'T AEREO',
                    'T AUTOMOTOR DE CARGA',
                    'T FERROVIARIO',
                    'T FORANEO DE PASAJEROS',
                    'TABACO',
                    'TELEFONIA',
                    'TELEFONIA MOVIL',
                    'TIEMPOS COMPARTIDOS',
                    'TIENDAS DEPARTAMENTALES',
                    'TRANSPORTES',
                    'TUBOS DE ACERO',
                    'TV ABIERTA',
                    'TV RESTINGIDA',
                    'UNIONES DE CREDITO',
                    'VIDRIO Y SUS PRODUCTOS',
                    'VIVIENDA DE I S',
                    'VIVIENDA MEDIA Y RESID',
                    'CARGA INICAL',
                    'G13 GOBIERNO',
                ],
                certificacion: [
                    'AUDITADA S/ SALVEDADES',
                    'AUDITADA C/ SALVEDADES',
                    'BMV',
                    'INFORMATICion INTERNA',
                    'CARGA INICIAL',
                    'NO APLICA',
                ],
                estado: ['AGUASCALIENTES', 'BAJA CALIFORNIA', 'BAJA CALIFORNIA SUR', 'CAMPECHE', 'CHIAPAS', 'CHIHUAHUA', 'COAHUILA DE ZARAGOZA', 'COLIMA', 'CIUDAD DE MEXICO', 'DURANGO', 'GUANAJUATO', 'GUERRERO', 'HIDALGO', 'JALISCO', 'ESTADO DE MEXICO', 'MICHOACAN DE OCAMPO', 'MORELOS', 'NAYARIT', 'NUEVO LEON', 'OAXACA', 'PUEBLA', 'QUERETARO DE ARTEAGA', 'QUINTANA ROO', 'SAN LUIS POTOSI', 'SINALOA', 'SONORA', 'TABASCO', 'TAMAULIPAS', 'TLAXCALA', 'VERACRUZ DE IGNACIO DE LA LLAVE', 'YUCATAN', 'ZACATECAS'],
                procedencia: ['NACIONAL', 'INTERNACIONAL']
            }
        }
    },
    computed: {
        validateForm() {
            let valid = true

            let keys = ['desde', 'hasta']

            keys.forEach(key => {
                if(valid) {
                    valid = !!this.form[key]
                }
            })

            return valid
        },
    },

    methods: {
        submit() {
            if(!this.validateForm) {
                alert('FORMULARIO INVALIDO')
            }
            this.clientsNewExercise({...this.form})
            this.$emit('close')
        },
        ...mapMutations({
            clientsNewExercise: 'clients/newExercise'
        })
    }
}
</script>
<template>
    <div class="ex-modal__wrap">
        <div class="ex-modal__header">
            <h1>Periodo del Ejercicio</h1>
            <button class="ex-modal__header-close" @click="$emit('close')"><i class="fas fa-times"></i></button>
        </div>

        <div class="ex-modal__body">
            <div class="mt-4 px-3">
                <div class="border">
                    <div class="section__header">
                        <h2>Mes/Año</h2>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-2/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Desde</label> <input class="border" type="date" v-model="form.desde">
                        </div>
                        <div class="w-2/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Hasta</label> <input class="border" type="date" v-model="form.hasta">
                        </div>
                        <div class="w-5/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Segmento:</label> <select class="border w-full text-sm" v-model="form.segmento" disabled>
                                <option value=""></option>
                            </select>
                        </div>
                        <div class="w-3/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">En:</label> 
                            <select class="border w-full text-sm" v-model="form.en">
                                <option value="">[SELECCIONE]</option>
                                <option v-for="(en, index) in lists.en" :key="`en-${index}`" :value="en">{{ en }}</option>
                            </select>
                        </div>
                        <div class="w-4/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Tribulación:</label> 
                            <select class="border w-full text-sm" v-model="form.tribulacion">
                                <option value="">[SELECCIONE]</option>
                                <option v-for="(tribulacion, index) in lists.tribulacion" :key="`tribulacion-${index}`" :value="tribulacion">{{ tribulacion }}</option>
                            </select>
                        </div>
                        <div class="w-5/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Estructura Prod:</label> 
                            <select class="border w-full text-sm" v-model="form.estructura_prod">
                                <option value="">[SELECCIONE]</option>
                                <option v-for="(estructura_prod, index) in lists.estructura_prod" :key="`estructura_prod-${index}`" :value="estructura_prod">{{ estructura_prod }}</option>
                            </select>
                        </div>
                        <div class="w-5/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Certificación:</label> 
                            <select class="border w-full text-sm" v-model="form.certificacion">
                                <option value="">[SELECCIONE]</option>
                                <option v-for="(certificacion, index) in lists.certificacion" :key="`certificacion-${index}`" :value="certificacion">{{ certificacion }}</option>
                            </select>
                        </div>
                        <div class="w-4/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Estado:</label> 
                            <select class="border w-full text-sm" v-model="form.estado">
                                <option value="">[SELECCIONE]</option>
                                <option v-for="(estado, index) in lists.estado" :key="`estado-${index}`" :value="estado">{{ estado }}</option>
                            </select>
                        </div>
                        <div class="w-3/12 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Num emplados:</label> 
                            <input type="text" class="border w-full text-sm" v-model="form.num_empleados">
                        </div>
                        
                    </div>
                    <hr class="border">
                    <div class="section__header mt-5">
                        <h2>Datos del Auditor de Balance</h2>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="w-1/2 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Nombre: </label> <input class="border w-full text-sm" v-model="form.nombre">
                        </div>
                       
                        <div class="w-1/2 p-1 flex items-center">
                            <label class="text-sm whitespace-nowrap">Procedencia: </label> 
                            <select class="border w-full text-sm" v-model="form.procedencia">
                                <option value="">[SELECCIONE]</option>
                                <option v-for="(procedencia, index) in lists.procedencia" :key="`procedencia-${index}`" :value="procedencia">{{ procedencia }}</option>
                            </select>
                        </div>
                        <div class="w-3/12 p-1 flex ">
                            <label class="text-sm whitespace-nowrap">Salvedades: </label> 
                            <div class="text-center px-2">
                                <p class="m-0 text-sm">SI</p>
                                <input type="radio" value="SI" name="salvedades" v-model="form.salvedades">
                            </div>
                            <div class="text-center px-2">
                                <p class="m-0 text-sm">NO</p>
                                <input type="radio" value="NO" name="salvedades" v-model="form.salvedades">
                            </div>
                        </div>
                        <div class="w-full p-1 flex mr-auto me-auto">
                            <label class="text-sm whitespace-nowrap">Descripción <br> de  Salvedades: </label> 
                            <textarea class="w-full border" rows="5" v-model="form.descripcion_de_salvedades"></textarea>
                        </div>
                    </div>
                    <hr class="border">
                    <div class="flex justify-end py-3 px-2">
                        <button class="btn  font-bold px-5 mr-3" :class="{'btn-green': validateForm}" :disabled="!validateForm" @click="submit">Guardar</button> <button class="btn btn-green font-bold px-5" @click="$emit('close')">Cerrar</button>
                    </div>
                    <hr class="border">
                    <div class="py-3"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
$modal_color: #0078ff;
.ex-modal{
    &__wrap{
        border: 2px solid $modal_color;
        min-height: 50vh;
    }
    &__header{
        background: $modal_color;
        color: #fff;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 0.25rem;
        position: relative;
        &-close{
            position: absolute;
            right: 1rem;
            top: 0.25rem;
            background: #0040a6;
            width: 1rem;
            height: 1rem;
            padding: 0;
            line-height: 1rem;
        }
    }
}
</style>