export default [
    'GOBIERNO',
    'SA',
    'SA DE CV',
    'S DE RL',
    'SC',
    'AC',
    'S EN C',
    'S EN C POR A',
    'S EN NC',
    'EXTRANJERO',
    'SAPI DE CV',
    'FID',
    'SPR DE RL DE CV',
    'SAS',
    'SAS DE CV',
    'SPR DE RL',
    'SC DE RL DE CV',
    'SA DE CV SOFOM ER',
    'SA DE CV SOFOM ENR',
    'SAPI DE CV SOFOM ENR',
    'S. DE SS',
    'AR',
]