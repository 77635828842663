<script>
import AdminInformation from '@/components/CreditLens/Details/IdentityInformation/AdminInformation.vue';
import AdminHierarchy from '@/components/CreditLens/Details/IdentityInformation/AdminHierarchy.vue';
import AdminDocument from '@/components/CreditLens/Details/IdentityInformation/AdminDocument.vue';
import TabsVue from '@/components/CreditLens/TabsVue.vue'
export default {
    props: ['client'],
    components: {
        TabsVue,
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Administrar información',
                    component: AdminInformation
                },
                {
                    title: 'Administrar jerarquía',
                    component: AdminHierarchy
                },
                {
                    title: 'Documento',
                    component: AdminDocument
                },
            ]
        }  
    },
}
</script>
<template>
    <div class="py-2">
        <div class="text-lg px-5 py-2">
            <span class="text-success"><i class="fas fa-download"></i></span> <span class="text-blue-600"><i class="fas fa-angle-down"></i></span>
        </div>
        <TabsVue :tabs="tabs"/>
    </div>
</template>