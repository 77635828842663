<script>
import RiLoading from '@/components/RI/Loading.vue'
import { mapMutations } from 'vuex'
export default {
    components:{
        RiLoading
    },
    methods: {
        ...mapMutations({
            'loadingSetStatus': 'loading/setStatus',
            'loadingSetMessage': 'loading/setMessage',
        })
    },
    mounted() {
        this.loadingSetStatus(true)
        this.loadingSetMessage('Creando Escritorio')
        setTimeout(() => {
            this.$router.push({name:'RiHome'})
            this.loadingSetMessage('Procesando su petición')
        }, 1000);
    }
}
</script>

<template>
    <div>
        <header class="header p-2">
           <div>
                <span>
                    <i class="fas fa-chevron-down"></i>
                </span>
                <span>
                    Mi sesión
                </span>
           </div>
           <div class="mx-auto">
                <span>NOMBRE ASESOR</span>
           </div>
          
           <div>
                <span>Salir</span>
           </div>
        </header>
        <div class="spacer-h"></div>
        <section class="login__main">
           
        </section>
    </div>
</template> 
<style lang="scss" scoped>
.btn{
    font-size: 1.2rem;
}
.app-input{
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    height: 36px;
    width: 100%;
    padding: 0.25rem;
    font-size: 1.1rem;
}
.header{
    display: flex;
    background: #dfdfdf;
    align-items: center;
    color: #7c898d;
    font-weight: bold;
    
}
.spacer-h{
    height: 6rem;
    background: #fff;
}
.login{
    &__main{
        background-image: url('/public/imgs/bg_inter.png');
        min-height: 100vh;
        background-size: auto 100%;
        display: flex;
        align-items: center;
    }
    &__form{
        margin: auto;
        display: block;
        width: 80%;
        max-width: 380px;
        margin: 0 auto;
        border: 1px solid #7c898d;
        background-color: #f1fdfa;
        padding: 0.75rem;
        border-radius: 1rem;
        padding-bottom: 2rem;
    }
    &__header{
        display: flex;
        font-size: 1.2rem;
        color: #056fb6;
    }
}
</style>