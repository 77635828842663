<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            periods: null,
            primary: ['activo_circulante', 'activos_no_circulantes'],
            menuNav: [
                {
                    label: 'Todo',
                    slug: null,
                    active: true
                },
                {
                    label: 'Activo circulante',
                    slug: 'activo_circulante',
                    active: false
                },
                {
                    label: 'Activos no ciculantes',
                    slug: 'activos_no_circulantes',
                    active: false
                },
                {
                    label: 'Pasivo circulante',
                    slug: 'pasivo_circulante',
                    active: false
                },
                {
                    label: 'Pasivos no circulantes',
                    slug: 'pasivos_no_circulantes',
                    active: false
                },
                {
                    label: 'Otros pasivos / Capital',
                    slug: 'otros_pasivos_capital',
                    active: false
                },
                {
                    label: 'Capital contable / Patrimonio',
                    slug: 'capital_contable_patrimonio',
                    active: false
                },
                {
                    label: 'Ingresos y gastos',
                    slug: 'ingresos_y_gastos',
                    active: false
                }
            ],
            selectedSlug: null
        }
    },
    computed: {
        rowsBasic() {
            return Object.keys(this.clientsConfig.basic) 
        },  
        ...mapGetters({
            'clientsStaging': 'clients/staging',
            'clientsConfig': 'clients/config',
        })
    },
    methods: {
        addNew() {
            let template = JSON.parse(JSON.stringify(this.periods[0]))
            for (let key in template) {
                if(!['id', 'bbvaid', 'basic'].includes(key)) {
                        for (let key2 in template[key]) {
                            template[key][key2] = ''
                        }
                }
            }
            template.id = Date.now()
            template.basic.estado = 'Borrador'
            this.periods.push(template)
        },
        clone() {
            let template = JSON.parse(JSON.stringify(this.periods[0]))
            template.id = Date.now()
            template.basic.estado = 'Borrador'
            this.periods.push(template)
        },
        sumCells(dataSet, items, key, config) {
            let total = 0

            if(items && items.length) {
                items.forEach(item => {
                    total += Number(dataSet[item])
                });
            } else {
                for (const keyd in dataSet) {
                    if(keyd == key || ['sum', 'sum_sub'].includes(config[keyd].type)) {
                        
                    } else {
                        total += Number(dataSet[keyd])
                    }
                }
            }

            dataSet[key] = total

            return total
        },
        sumSubCells(dataSet, sum = [], sub = [], key) {
            let sumTotal = 0
            let subTotal = 0

            if(sum && sum.length) {
                sum.forEach(item => {
                    sumTotal += Number(dataSet[item])
                });
            } 
           
            if(sub && sub.length) {
                sub.forEach(item => {
                    subTotal += Number(dataSet[item])
                });
            } 

            let result = sumTotal - subTotal

            dataSet[key] = result

            return result
        },
        setActive(menu) {
            this.menuNav.forEach(m => {
                m.active = false
            })

            menu.active = true
            this.selectedSlug = menu.slug
        },  
        ...mapMutations({
            'clientsSetCurrent': 'clients/setCurrent'
        })
    },  
    mounted() {
        let periods = JSON.parse(JSON.stringify(this.clientsStaging.periods))
        this.$set(this, 'periods', periods)
    }
}
</script>
<template>
    <div class="p-2">
        <div class="flex items-center">
            <button class="p-1 text"><i class="fas fa-save"></i></button>
            <button class="p-1 text"><i class="fas fa-share-square"></i></button>
            <button class="p-1 text"><i class="fas fa-share-square"></i></button>
            <button class="p-1 text-blue-primary"><i class="fas fa-caret-square-right"></i></button>
            <span class="separetor-y"></span>
            <button class="p-1 text-green-500" @click="addNew"><i class="far fa-plus-square"></i></button>
            <button class="p-1 text-blue-primary" @click="clone"><i class="far fa-copy"></i></button>
            <button class="p-1 text-blue-primary"><i class="fas fa-list"></i></button>
            <button class="p-1 text-blue-primary"><i class="fas fa-sitemap"></i></button>
            <button class="p-1 text-blue-primary"><i class="far fa-sticky-note"></i></button>
            <span class="separetor-y"></span>
            <button class="p-1 text-blue-primary"><i class="far fa-file"></i></button>
            <span class="separetor-y"></span>
            <div class="px-1">
                <p class="text-xs">Fórmula</p>
                <input class="border" type="text" name="" id="">
            </div>
            <div class="ml-10">
                <span class="text-blue-primary">Período <i class="fas fa-chevron-down"></i></span>
                <span class="text-blue-primary ml-3">Cuenta <i class="fas fa-chevron-down"></i></span>
                <span class="text-blue-primary ml-3">Configuración de preferencias <i class="fas fa-chevron-down"></i></span>
            </div>
        </div>
        <div class="flex">
            <div class="flex ml-auto">
                <p class=" mr-2 whitespace-nowrap">Mostrar cuenta con valores unícamente </p> 
                <div class="relative w-9/12 flex">
                    <label class="switch">
                        <input type="checkbox"> 
                        <div class="switch-border"></div>
                        <span class="switch-indicator"></span>
                    </label>
                </div>
            </div>
           
        </div>
        <div>
            <div class="flex analysis-nav">
                <button class="button analysis-nav-item"><i class="fas fa-caret-down"></i></button>
                <button class="button analysis-nav-item" :class="{'analysis-nav-item-active': menu.active}" v-for="(menu, index) in menuNav" :key="`menunav-${index}`" @click="setActive(menu)">{{ menu.label }}</button>
            </div>
        </div>
        <div v-if="periods" class="periods">
            <table class="table-registers">
                <!-- BASICO -->
                <tr v-for="(row, key) in clientsConfig.basic" :class="{'tr-primary': primary.includes(key)}">
                    <td>
                        <span v-if="primary.includes(key)" class="text-blue-600 icon-search"><i class="fas fa-search"></i></span>
                        {{ row.label }} <span class="text-red-600" v-if="row.required">*</span>
                    </td>
                    <td v-for="(period, index) in periods">
                        <input class="text-right" type="text" v-model="period.basic[key]" v-if="row.type == 'string'">
                        <input class="text-right" type="date" v-model="period.basic[key]" v-else-if="row.type == 'date'">
                        <select v-model="period.basic[key]" v-if="row.type == 'select'">
                            <option v-for="(option, index) in row.options" :value="option.value">{{ option.label }}</option>
                        </select>
                    </td>
                </tr>
                <!-- Activo ciculante -->
                <template v-if="!selectedSlug || selectedSlug == 'activo_circulante'">
                    <tr v-for="(row, key) in clientsConfig.activo_circulante" :class="{'tr-primary': primary.includes(key), 'tr-warning': ['sum', 'sum_sub'].includes(row.type)}" >
                        <td>
                            <span v-if="primary.includes(key)" class="text-blue-600 icon-search"><i class="fas fa-search"></i></span>   
                            <span :class="{'font-bold': key =='activo_circulante'}">{{ row.label }} <span class="text-red-600" v-if="row.required">*</span></span> 
                        </td>
                        <td v-for="(period, index) in periods" class="text-right">
                            <input class="text-right px-3" type="text" v-model="period.activo_circulante[key]" v-if="row.type == 'string'">
                            <input class="text-right px-3" type="text" v-model="period.activo_circulante[key]" v-else-if="row.type == 'number'">
                            <select class="text-right px-3" v-model="period.activo_circulante[key]" v-else-if="row.type == 'select'">
                                <option v-for="(option, index) in row.options" :value="option.value">{{ option.label }}</option>
                            </select>
                            <span class="text-right px-3" v-else-if="row.type == 'sum'">
                                {{  sumCells(period.activo_circulante, row.items, key, clientsConfig.activo_circulante)  }}
                            </span>
                        </td>
                    </tr>
                </template>
                <!-- Activo no circulante -->
                <template v-if="!selectedSlug || selectedSlug == 'activos_no_circulantes'">
                    <tr v-for="(row, key) in clientsConfig.activos_no_circulantes" :class="{'tr-primary': primary.includes(key), 'tr-warning': ['sum', 'sum_sub'].includes(row.type)}">
                        <td>
                            <span v-if="primary.includes(key)" class="text-blue-600 icon-search"><i class="fas fa-search"></i></span>
                            <span :class="{'font-bold': key =='activos_no_circulantes'}">{{ row.label }} <span class="text-red-600" v-if="row.required">*</span></span> 
                        </td>
                        <td v-for="(period, index) in periods" class="text-right">
                            <input class="text-right px-3" type="text" v-model="period.activos_no_circulantes[key]" v-if="row.type == 'string'">
                            <input class="text-right px-3" type="text" v-model="period.activos_no_circulantes[key]" v-else-if="row.type == 'number'">
                            <select class="text-right px-3" v-model="period.activos_no_circulantes[key]" v-else-if="row.type == 'select'">
                                <option v-for="(option, index) in row.options" :value="option.value">{{ option.label }}</option>
                            </select>
                            <span class="text-right px-3" v-else-if="row.type == 'sum'">
                                {{  sumCells(period.activos_no_circulantes, row.items, key, clientsConfig.activos_no_circulantes)  }}
                            </span>
                            <span class="text-right px-3" v-else-if="row.type == 'sum_sub'">
                                {{  sumSubCells(period.activos_no_circulantes, row.sum, row.sub, key)  }}
                            </span>
                        </td>
                    </tr>
                </template>
            </table>
             
        </div>
    </div>
</template>

<style lang="scss" scoped>
.analysis-nav{
    overflow: hidden;
}
.analysis-nav-item{
    border-radius: 0;
    border-bottom: transparent;
    white-space: nowrap;
    &-active{
        background-color: #c9c9c9;
    }
}
.periods{
    height: calc(100vh - 258px);
    overflow: auto;
}
</style>