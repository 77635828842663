
<script>
import RiModal from '@/components/RI/Modal.vue'
import RIHeader from '@/components/RI/HeaderComp.vue'
import RIModalNewExercise from '@/components/RI/modals/ModalNewExercise.vue'
import RIModalEditItem from '@/components/RI/modals/ModalEditItem.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            tab: 'lista_rating',
            tab_exercise: 'info_economica',
            id_ejecicio: null,
            selected: null,
            showing: [],
            pendingUpdate: false,
            qualitative_item_selected: null,
            enabledUpdate: false,
            enabledCalculate: false,
            rating_selected: null,
            type_report: ''
        }
    },
    computed: {
        validQualitative() {
            let valid = true;

            this.clientsConfig.qualitative_items.forEach(item => {
                if(valid && !this.selected.info_cualitativa.analisis[item.key]) {
                    valid = false
                }
            })

            return valid
        },
        ...mapGetters({
            clientsCurrent: 'clients/current',
            clientsConfig: 'clients/config',
            'loadingStatus': 'loading/status',
            'loadingMessage': 'loading/message'
        })
    },
    watch: {
        id_ejecicio(nVal, oVal) {
            this.setSelected(nVal)
        },
      
    },
    components: {
        RiModal,
        RIHeader,
        RIModalNewExercise,
        RIModalEditItem
    },

    methods: {
        goToRatingList() {
            this.$refs.modalSaved.close()
            this.$refs.modalRating.open()
            this.tab = 'lista_rating'
        },
        saveAll() {
            this.setEnableUpdate(false)
            this.loadingSetStatus(true)
            this.loadingSetMessage('Procesando su petición')
            setTimeout(() => {
                this.clientsUpdateExercise(this.selected)
                this.loadingSetStatus(false)
                this.$refs.modalSaved.open()
            }, 3000)
        },
        setEnableUpdate(status = true) {
            this.enabledUpdate = status
            // this.saveQualitative(false)
        },
        saveCalculate() {
            // this.enabledUpdate = true
            this.saveQualitative(false)
        },
        updateQualitative(value) {
            this.selected.info_cualitativa.analisis[this.qualitative_item_selected.key] = value
        },
        saveQualitative(enabledCalculate = true) {
            this.loadingSetStatus(true)
            this.loadingSetMessage('Procesando su petición')
            this.enabledCalculate = enabledCalculate
            setTimeout(() => {
                this.loadingSetStatus(false)
                this.$refs.modalUpdated.open()
                this.clientsUpdateExercise(this.selected)
            }, 3000)
        },
        openEditItem(item) {
            this.$set(this, 'qualitative_item_selected', item)
            this.$refs.modalEditItem.open()
        },  
        setPendingUpdate() {
            this.pendingUpdate = true
        },
        updateExercise() {
            this.loadingSetStatus(true)
            this.loadingSetMessage('Procesando su petición')
            let loopExercise = (object, dataSet) => {
                for (const property in object) {
                    if(object[property].children) {
                        let data  = dataSet[property]
                        loopExercise(object[property].children, data)
                    }
                    if(object[property].type == 'sum') {
                        let data  = object[property].children ? dataSet[property] : dataSet
                        this.sumRI(data, object[property], property)
                    }

                    object
                   
                    if(object[property].type == 'sum_sub') {
                        let data  = object[property].children ? dataSet[property] : dataSet
                        this.sumSubRI(data, object[property], property)
                    }

                }
            }

            setTimeout(() => {
                this.loadingSetStatus(false)
                loopExercise(this.clientsConfig.info_economica, this.selected.info_economica)
                this.showing = []
                this.$refs.modalUpdated.open()
                this.pendingUpdate = false
                this.clientsUpdateExercise(this.selected)
            }, 3000)

        },

        sumRI(dataSet, config, property) {
            if(property == 'utilida_bruta') {
                console.log('SUM')
            }
            let total = 0
            let items = config.items 
            // if(property == 'ventas_netas_totales') {
            //     console.log(property, dataSet, items)
            // }
            if(items == '*') {
                for (const key in dataSet) {
                    if(key == property) {
                        continue
                    } else {
                        if(typeof dataSet[key] == 'object') {
                            total += Number(dataSet[key][key])
                        } else {
                            total += Number(dataSet[key])
                        }
                    }
                }
            } else if (items && items.length) {
               
                items.forEach(item => {
                    if(property == 'ventas_netas_totales') {
                        console.log(item, dataSet[item])
                    }
                    total += Number(dataSet[item])
                });
            }

            dataSet[property] = total

            return total 
        },
        sumSubRI(dataSet, config, property) {
            if(property == 'utilida_bruta') {
                console.log('SUMSUB')
            }
            let sumTotal = 0
            let subTotal = 0
            let itemsSum = config.sum 
            let itemsSub = config.sub
          
            if(itemsSum == '*') {
                for (const key in dataSet) {
                    if(key == property) {
                        continue
                    } else {
                        if(typeof dataSet[key] == 'object') {
                            sumTotal += Number(dataSet[key][key])
                        } else {
                            sumTotal += Number(dataSet[key])
                        }
                    }
                }
            } else if (itemsSum && itemsSum.length) {
                itemsSum.forEach(item => {
                    sumTotal += Number(dataSet[item])
                });
            }
           
            if(itemsSub == '*') {
                for (const key in dataSet) {
                    if(key == property) {
                        continue
                    } else {
                        if(typeof dataSet[key] == 'object') {
                            subTotal += Number(dataSet[key][key])
                        } else {
                            subTotal += Number(dataSet[key])
                        }
                    }
                }
            } else if (itemsSub && itemsSub.length) {
                itemsSub.forEach(item => {
                   
                    subTotal += Number(dataSet[item])
                });
            }

            if(property == 'utilida_bruta') {
                console.log(sumTotal, subTotal)
            }

            dataSet[property] = sumTotal - subTotal

            return sumTotal - subTotal 
        },

        sumCells(dataSet, items, key, config) {
            let total = 0

            if(items && items.length) {
                items.forEach(item => {
                    total += Number(dataSet[item])
                });
            } else {
                for (const keyd in dataSet) {
                    if(keyd == key || ['sum', 'sum_sub'].includes(config[keyd].type)) {
                        
                    } else {
                        total += Number(dataSet[keyd])
                    }
                }
            }

            dataSet[key] = total

            return total
        },

        sumSubCells(dataSet, sum = [], sub = [], key) {
            let sumTotal = 0
            let subTotal = 0

            if(sum && sum.length) {
                sum.forEach(item => {
                    sumTotal += Number(dataSet[item])
                });
            } 
           
            if(sub && sub.length) {
                sub.forEach(item => {
                    subTotal += Number(dataSet[item])
                });
            } 

            let result = sumTotal - subTotal

            dataSet[key] = result

            return result
        },

        setSelected(id) {
            let exercise = this.clientsCurrent.ejercicios.find(ejecicio => ejecicio.id == id)

            if(exercise) {
                exercise = JSON.parse(JSON.stringify(exercise))
                this.$set(this, 'selected', exercise)
            }
        },
        openModalNew() {
            this.$refs.modalNew.open()
        }, 
        setTab(payload) {
            this.tab = payload
        },
        setTabByName(tab, payload) {
            this[tab] = payload
        },
        showResults() {
            if(this.results.length) {
                this.$refs.modalSearchResults.open()
            }
        },
        toggleShowing(key) {
            let newVal = this.showing.includes(key) ? this.showing.filter(el => el !== key) : [...this.showing, key];
            debugger
            this.$set(this, 'showing', newVal)
        },  

        selectClient(client) {
            this.loadingSetStatus(true)
            this.loadingSetMessage('Procesando tu petición')
            this.$refs.modalSearchResults.close()
            this.clientsSetCurrent(client)
        },
        
        ...mapMutations({
            'loadingSetStatus': 'loading/setStatus',
            'loadingSetMessage': 'loading/setMessage',
            'clientsSetCurrent': 'clients/setCurrent',
            'clientsUpdateExercise': 'clients/updateExercise',
        })
    },

    mounted() {
        this.clientsSetCurrent(this.$route.params.id)
        setTimeout(() => {
            this.loadingSetStatus(false)
        }, 1000)
    }


}
</script>
<template>
    <div>
        <RIHeader @search="showResults"/>
        <div class="header__light-blue flex items-center">
            <ul class="flex text-sm items-center ul-menu">
                <li class="px-2">Operación de Cuentas <i class="fas fa-sort-down text-white"></i></li>
                <li class="px-2">Servicios <i class="fas fa-sort-down text-white"></i>
                    <ul>
                        <li class="p-2">Verificaciones</li>
                        <li class="p-2">Aclaraciones <i class="fas fa-caret-right text-black"></i></li>
                        <li class="p-2">Estado de Cuenta <i class="fas fa-caret-right text-black"></i>
                            <ul>
                                <li class="p-2">Inscribir Impresión de Cuenta</li>
                            </ul>
                        </li>
                        <li class="p-2">Solicitud Derechos ARCO</li>
                        <li class="p-2">Mantenimiento TIB</li>
                        <li class="p-2">Mantenimiento Canales <i class="fas fa-caret-right text-black"></i></li>
                        <li class="p-2">Asociar Teléfono Celular</li>
                        <li class="p-2">Cartas PDV <i class="fas fa-caret-right text-black"></i></li>
                        <li class="p-2">Constancias SAT</li>
                        <li class="p-2">Rating <i class="fas fa-caret-right text-black"></i>
                            <ul>
                                <li class="p-2">Tomas de Información</li>
                                <li class="p-2">Consulta</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="px-2">Análisis <i class="fas fa-sort-down text-white"></i></li>
                <li class="px-2">Desembolso <i class="fas fa-sort-down text-white"></i></li>
                <li class="px-2">Seguimiento <i class="fas fa-sort-down text-white"></i></li>
                <li>Modificar</li>
            </ul>
        </div>
        <div class="header__dark-blue flex ">
            <ul class="flex text-sm items-center ul-menu">
                <li class="px-2 font-bold">Accesos Rápidos:</li>
                <li class="px-2">Modificar Datos Cliente</li>
                <li class="px-2">Consultar BNC</li>
                <li class="px-2">Validación INE</li>
            </ul>
        </div>
        <div class="content py-2 px-1" >

            <div class="section__header section__header--blue">
                <h2 class="text-white">Rating</h2>
            </div>

            <div class="px-2 mt-3">
                <div class="section__header py-0">
                    <ul class="tabs">
                        <li class="tab" :class="{'tab--active': tab == 'lista_rating'}" @click="setTab('lista_rating')">Lista de Ratings</li> 
                        <li class="tab" :class="{'tab--active': tab == 'datos_del_ejercicio'}" @click="setTab('datos_del_ejercicio')">Datos del Ejercicio</li>
                        <li class="tab" :class="{'tab--active': tab == 'analisis'}" @click="setTab('analisis')">Análisis</li>
                    </ul>
                </div>

                <div class="border " v-if="tab == 'lista_rating'">
                    <div>
                        <div class="h-48 mt-5 overflow-auto">
                            <table class="table-blue w-full">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Status</th>
                                        <th>Calif</th>
                                        <th>T. Análisis</th>
                                        <th>F. Análisis</th>
                                        <th>Últ. Ejer. Cerrado</th>
                                        <th>P.F. Cuant</th>
                                        <th>P.F. Cual</th>
                                        <th>N. V. Aler. Expl</th>
                                        <th>N. V. Aler. Cond</th>
                                        <th>Sucursal</th>
                                        <th>Usuario</th>
                                    </tr>
                                </thead>
                                <tbody v-if="clientsCurrent && clientsCurrent.hasOwnProperty('ratings')">
                                    <tr v-for="(rating, index) in clientsCurrent.ratings || []" :key="`rating-${index}`">
                                        <td class="text-center">
                                            <input type="radio" name="select_rating" :value="rating.id" v-model="rating_selected">
                                        </td>
                                        <td class="text-center">
                                            {{ rating.status }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.calif }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.t_analisis }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.f_analisis }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.ult_ejer_cerrado }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.pf_cuant }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.pf_cual }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.nv_aler_expl }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.nv_aler_cond }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.sucursal }}
                                        </td>
                                        <td class="text-center">
                                            {{ rating.usuario }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="px-2 mt-8">
                        <div class="border py-2 px-3">
                            <div class="flex flex-wrap items-end">
                                <div class="w-1/4 px-2">
                                    <label class="block w-full">Politica de Riesgo</label>
                                    <input class="border block w-full" type="text">
                                </div>
                                <div class="w-3/4 px-2">
                                    <div class="flex">
                                        <button class="w-1/5 btn btn-green  font-bold ml-auto">Rating CNBV</button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap mt-1">
                                <div class="w-1/4 px-2">
                                    <select class="border block w-full" name="" id="" :disabled="!rating_selected" v-model="type_report">
                                        <option value="">[SELECCIONE]</option>
                                        <option value="Balance">Balance</option>
                                        <option value="Estado de Resultados">Estado de Resultados</option>
                                        <option value="Flujo de Efectivo">Flujo de Efectivo</option>
                                        <option value="Ratios">Ratios</option>
                                    </select>
                                </div>
                                <div class="w-3/4 px-2">
                                    <div class="flex">
                                        <button class="w-1/5 btn  mr-1 font-bold" :class="{'btn-green': type_report}" :disabled="!type_report">Imprimir</button>
                                        <button class="w-1/5 btn mr-1 font-bold" disabled>Ajuste Override</button>
                                        <button class="w-1/5 btn mr-1 font-bold" disabled>Consultas</button>
                                        <button class="w-1/5 btn mr-1 font-bold" disabled>Validar</button>
                                        <button class="w-1/5 btn btn-green  font-bold" @click="setTab('datos_del_ejercicio')">Nuevo Rating</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="border " v-else-if="tab == 'analisis'">
                    <div class="flex flex-wrap mt-5 ">
                        <div class="h-48 overflow-auto w-9/12">
                            <table class="table-blue w-full">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Periodo</th>
                                        <th>Ejercicio</th>
                                        <th>Modelo</th>
                                        <th>Descripción</th>
                                        <th>Certificación</th>
                                        <th>F. Análisis</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(analisis, index) in clientsCurrent.analisis" :key="`rating-${index}`">
                                        <td class="text-center">
                                            <input type="checkbox" name="select">
                                        </td>
                                        <td class="text-center">
                                            {{ analisis.periodo }}
                                        </td>
                                        <td class="text-center">
                                            {{ analisis.ejercicio }}
                                        </td>
                                        <td class="text-center">
                                            {{ analisis.modelo }}
                                        </td>
                                        <td class="text-center">
                                            {{ analisis.descripcion }}
                                        </td>
                                        <td class="text-center">
                                            {{ analisis.certificacion }}
                                        </td>
                                        <td class="text-center">
                                            {{ analisis.f_analisis }}
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="h-48 w-3/12 flex items-center justify-center	">
                            <button class="btn">Consultar</button>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-5 ">
                        <div class="h-48 overflow-auto w-full">
                            <table class="table-blue w-full">
                                <thead>
                                    <tr>
                                        <th>Descripción</th>
                                        <th>Primer Periodo</th>
                                        <th>Segundo Periodo</th>
                                        <th>Tercer Periodo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="4" class="text-center">No existen datos a mostrar</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="border " v-else-if="tab == 'datos_del_ejercicio'">
                    <div class="flex flex-wrap mt-5 ">
                        <div class="h-48 overflow-auto w-full">
                            <table class="table-blue mx-auto">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Desde</th>
                                        <th>Hasta</th>
                                        <th>Estudio Asoc.</th>
                                        <th>Ajustes</th>
                                        <th>Certific.</th>
                                        <th>St.</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ejercicio, index) in clientsCurrent.ejercicios" :key="`rating-${index}`">
                                        <td class="px-3 text-center">
                                            <input type="radio" name="select" :value="ejercicio.id" v-model="id_ejecicio">
                                        </td>
                                        <td class="px-3 text-center">
                                            {{  [ejercicio.desde, ["MM-YYYY", "MM-DD-YY", "YYYY-MM-DD"]]  | moment("MM-YYYY") }}
                                        </td>
                                        <td class="px-3 text-center">
                                            {{  [ejercicio.hasta, ["MM-YYYY", "MM-DD-YY", "YYYY-MM-DD"]]  | moment("MM-YYYY") }}
                                        </td>
                                        <td class="px-3 text-center">
                                            {{ ejercicio.estudio_asoc }}
                                        </td>
                                        <td class="px-3 text-center">
                                            {{ ejercicio.ajuste }}
                                        </td>
                                        <td class="px-3 text-center">
                                            {{ ejercicio.certificacion }}
                                        </td>
                                        <td class="px-3 text-center">
                                            {{ ejercicio.st }}
                                        </td>
                                        <td class="px-3 text-center">
                                            <div class="relative">
                                                <button class="btn-actions">
                                                    {{ ejercicio.acciones }} <i class="fas fa-caret-down text-lime-600"></i>
                                                </button>
                                                <div class="btn-actions__menu">
                                                    <button class="btn-actions__menu-item">
                                                        <span class="btn-actions__menu-icon"><i class="fas fa-pen"></i></span>
                                                        <span>Modificar</span>
                                                    </button>
                                                    <button class="btn-actions__menu-item">
                                                        <span class="btn-actions__menu-icon"><i class="fas fa-times"></i></span>
                                                        <span>Eliminar</span>
                                                    </button>
                                                    <button class="btn-actions__menu-item">
                                                        <span class="btn-actions__menu-icon"><i class="fas fa-file-alt"></i></span>
                                                        <span>Imprimir</span>
                                                    </button>
                                                    <button class="btn-actions__menu-item">
                                                        <span class="btn-actions__menu-icon"><i class="fas fa-user"></i></span>
                                                        <span>Ajuste</span>
                                                    </button>
                                                    <button class="btn-actions__menu-item">
                                                        <span class="btn-actions__menu-icon"><i class="fas fa-file-alt"></i></span>
                                                        <span>Comentarios</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="w-full flex">
                            <div class="w-1/4 px-2">
                               
                            </div>
                            <div class="w-3/4 px-6 flex">
                                <button class="w-1/5 ml-auto btn btn-green  font-bold ml-auto" @click="openModalNew">Nuevo</button>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-5 border">
                        <div class="overflow-auto w-full py-2 px-2">
                            <label>Modelo</label> <input type="text" class="border w-96 ">
                        </div>
                    </div>
                    <hr class="border">
                    <div class="mt-3 max-h-96 overflow-y-auto" v-if="selected">
                        <!-- <pre>{{ selected }}</pre> -->
                        <div class="section__header py-0">
                            <ul class="tabs">
                                <li class="tab" :class="{'tab--active': tab_exercise == 'info_economica'}" @click="setTabByName('tab_exercise','info_economica')">Info. Económica</li> 
                                <li class="tab" :class="{'tab--active': tab_exercise == 'info_cualitativa'}" @click="setTabByName('tab_exercise','info_cualitativa')">Info. Cualitativa</li>
                            </ul>
                        </div>
                        <div v-if="tab_exercise == 'info_economica'">
                            <div class="flex exercise__header py-3">
                                <div class="w-10/12">
                                    <p class="text-center text-primary font-bold">Descripción de la Cuenta</p>
                                </div>
                                <div class="w-2/12">
                                    <p class="text-left text-primary font-bold">MPX(MILES)</p>
                                </div>
                            </div>
                            <div v-for="(item, index) in clientsConfig.info_economica" :key="`i-${index}`">
                                <div class="">
                                    <div class="flex exercise__header px-2">
                                        <div class="w-10/12 font-bold"> 
                                            <button  @click="toggleShowing(index)" >
                                                <span >
                                                    <i v-if="showing.includes(index)"  class="far fa-minus-square" :key="`ii-${index}`"></i> 
                                                    <i v-else  class="far fa-plus-square" :key="`iii-${index}`"></i> 
                                                </span>
                                            </button>
                                           {{ item.label }}</div> 
                                        <div v-if="item.children && item.type != 'none'" class="font-bold w-2/12 text-right">{{ selected.info_economica[index][index] || '0' }}</div>
                                        <div v-else-if="item.type == 'none'" class="font-bold w-2/12 text-right"></div>
                                        <div v-else class="font-bold w-2/12 text-right">{{ selected.info_economica[index] || '' }}</div>
                                    </div>
                                    <div v-if="item.children && showing.includes(index)">
                                        <div v-for="(item_c, indexc) in item.children" :key="`ic-${indexc}`">
                                            <div class="px-2">
                                                <div class="flex">
                                                    <div class="px-4 w-10/12" :class="{'font-bold': item_c.children ||  item_c.type != 'number'}">
                                                        <button  @click="toggleShowing(indexc)">
                                                            <span v-if="item_c.children">

                                                                <i v-if="showing.includes(indexc)"  class="far fa-minus-square" ></i> 
                                                                <i v-else  class="far fa-plus-square" ></i> 
                                                            </span>
                                                        </button>
                                                        {{ item_c.label }}
                                                    </div>
                                                    <div v-if="item_c.children" class="text-right font-bold w-2/12">{{ selected.info_economica[index][indexc][indexc] || '0' }}</div>
                                                    <div v-else class="text-right w-2/12">
                                                        <input @change="setPendingUpdate" v-if="item_c.type == 'number'" class="border text-right" type="text" v-model="selected.info_economica[index][indexc]">
                                                        <span class="font-bold" v-else> {{ selected.info_economica[index][indexc] }}</span>
                                                    </div>
                                                </div>
                                                <div v-if="item_c.children && showing.includes(indexc)">
                                                    <div v-for="(item_c_c, indexcc) in item_c.children" :key="`icc-${indexcc}`">
                                                        <div class="">
                                                            <div class=" flex">
                                                                <div class="w-10/12 px-8">{{ item_c_c.label }}</div>
                                                                <div v-if="item_c_c.children" class="font-bold text-right w-2/12">{{ selected.info_economica[index][indexc][indexcc][indexcc] || '0' }}</div>
                                                                <div v-else class="text-right w-2/12">
                                                                    <input @change="setPendingUpdate" type="text" class="border text-right" v-model="selected.info_economica[index][indexc][indexcc]">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        <div v-if="tab_exercise == 'info_cualitativa'" class="px-2">
                            <div class="border my-2">
                                <div class="section__header">
                                    <h1>Cuantitativo-Econónomico Financiero</h1>
                                </div>
                                <div class="flex">
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <label class="text-sm whitespace-nowrap mr-2" for="">Puntuación Cuantitativa: </label>
                                        <input type="text" class="border w-20 text-right" disabled v-model="selected.info_cualitativa.puntuacion_cuantitativa">
                                    </div>
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <label class="text-sm whitespace-nowrap mr-2" for="">Sobre: </label>
                                        <input type="text" class="border text-right w-20" disabled v-model="selected.info_cualitativa.puntuacion_cuantitativa_sobre">
                                    </div>
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <button class="btn btn-green font-bold px-5 mr-3">Detalle</button>
                                    </div>
                                </div>
                            </div>
                            <div class="border my-2">
                                <div class="section__header">
                                    <h1>Análisis Cualitativo</h1>
                                </div>
                                <div class="max-h-56	overflow-y-auto	">
                                    <table class="table-blue w-full">
                                        <tbody>
                                            <tr v-for="(item, index) in clientsConfig.qualitative_items" :key="`quailitative-${index}-${item.key}`">
                                                <td class="w-full px-3">
                                                    {{ item.label }}
                                                </td>
                                                <td class="w-80">
                                                    <input class="border w-80" disabled type="text" v-model="selected.info_cualitativa.analisis[item.key]">
                                                </td>
                                                <td>
                                                    <button class="btn btn-green font-bold px-5 mr-3" @click="openEditItem(item)"><i class="fas fa-search text-white"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="flex">
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <label class="text-sm whitespace-nowrap mr-2" for="">Puntuación Cualitativa: </label>
                                        <input type="text" class="border w-20 text-right" disabled v-model="selected.info_cualitativa.puntuacion_cualitativa">
                                    </div>
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <label class="text-sm whitespace-nowrap mr-2" for="">Sobre: </label>
                                        <input type="text" class="border w-20 text-right" disabled v-model="selected.info_cualitativa.puntuacion_cualitativa_sobre">
                                    </div>
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <label class="text-sm whitespace-nowrap mr-2" for="">Puntuación Total: </label>
                                        <input type="text" class="border w-20 text-right" disabled v-model="selected.info_cualitativa.puntuacion_total">
                                    </div>
                                    <div class="w-3/12 flex p-2 items-center justify-center">
                                        <button class="btn btn-green font-bold px-5 ml-auto" :disabled="!validQualitative"  @click="saveQualitative">Guardar</button>
                                    </div>
                                </div>
                            </div>

                            <div class="flex">
                                <div class="border my-2 w-5/12">
                                    <div class="section__header">
                                        <h1>Señales de Alerta</h1>
                                    </div>
                                    <div class="flex flex-wrap">

                                        <div class="w-full flex p-1 items-center">
                                            <label class="text-sm whitespace-nowrap mr-2 w-1/2" for="">Variables a Explicar: </label>
                                            <input type="text" class="border w-20 text-right" disabled v-model="selected.info_cualitativa.variables_a_explicar">
                                            <button class="btn btn-green font-bold px-5 ml-auto">Detalle</button>
                                        </div>
                                        <div class="w-full flex p-1 items-center">
                                            <label class="text-sm whitespace-nowrap mr-2 w-1/2" for="">Variables Condicionales: </label>
                                            <input type="text" class="border text-right w-20" disabled v-model="selected.info_cualitativa.variables_condicionales">
                                            <button class="btn btn-green font-bold px-5 ml-auto">Detalle</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="border my-2 w-5/12 ml-auto">
                                    <div class="section__header">
                                        <h1>Rating del Cliente</h1>
                                    </div>
                                    <div class="flex flex-wrap">

                                        <div class="w-1/3 flex p-1 items-center">
                                            <input type="text" class="border w-20 text-right" disabled v-model="selected.info_cualitativa.variables_a_explicar">
                                        </div>
                                        <div class="w-2/3 flex p-1 items-center">
                                            <label class="text-sm whitespace-nowrap mr-2 " for="">P. Riesgo: </label>
                                            <select type="text" class="border text-right ml-auto" v-model="selected.info_cualitativa.p_riesgo" @change="setEnableUpdate">
                                                <option value="">[SELECIONES]</option>
                                                <option value="CRECER">CRECER</option>
                                                <option value="MANTENER">MANTENER</option>
                                                <option value="REDUCIR">REDUCIR</option>
                                                <option value="NO DETERMINADO">NO DETERMINADO</option>
                                            </select>
                                        </div>
                                        <div class="ml-auto text-center">
                                            <button class="btn btn-green font-bold px-5 ml-auto" disabled>Ayudas</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="py-3" v-if="selected && tab_exercise == 'info_economica'">
                        <hr class="border">
                        <div class="flex px-3 py-3">
                            <button class="btn btn-green font-bold px-5 mr-3 ml-auto" @click="updateExercise" :disabled="!pendingUpdate">Actualizar</button> 
                            <button class="btn btn-green font-bold px-5 mr-3">Cuadrar Balance</button>
                            <button class="btn btn-green font-bold px-5" disabled>C. Ratios</button>
                        </div>
                    </div>
                    <div class="py-3" v-if="selected && tab_exercise == 'info_cualitativa'">
                        <hr class="border">
                        <div class="flex px-3 py-3">
                            <button class="btn btn-green font-bold px-5 mr-3 ml-auto">Var. Buro</button> 
                            <button class="btn btn-green font-bold px-5 mr-3" @click="$refs.modalCalculate.open" :disabled="!enabledCalculate">Calcular</button>
                            <button class="btn btn-green font-bold px-5 mr-3" :disabled="!enabledUpdate" @click="saveAll">Actualizar</button>
                            <button class="btn btn-green font-bold px-5">Comentarios</button>
                        </div>
                    </div>

                </div>
                <div class="px-2 py-2">
                    <div class="px-3">
                        <div class="flex flex-wrap mt-1">
                            <div class="w-1/4 px-2">
                               
                            </div>
                            <div class="w-3/4 px-2">
                                <div class="flex">
                                    <button class="w-1/5 btn btn-green  font-bold ml-auto">Salir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <ri-modal ref="modalNew" size=" md:w-6/12">
            <template v-slot:body>
                <RIModalNewExercise @close="$refs.modalNew.close"/>
            </template>
        </ri-modal>
        <ri-modal ref="modalEditItem" size=" md:w-6/12" >
            <template v-slot:body>
                <RIModalEditItem @close="$refs.modalEditItem.close"  @save="updateQualitative" :item="qualitative_item_selected"/>
            </template>
        </ri-modal>
        <ri-modal ref="modalCalculate" size=" md:w-6/12" >
            <template v-slot:body>
                <div class="ex-modal__wrap">
                    <div class="ex-modal__header">
                        <h1>Rating Información Cualitativa Preguntas Abiertas</h1>
                        <button class="ex-modal__header-close" @click="$refs.modalCalculate.close"><i class="fas fa-times"></i></button>
                    </div>

                    <div class="ex-modal__body">
                        <div class="mt-4 px-3">
                            <div class="border">
                                <div class="section__header">
                                    <h2>Otras Preguntas</h2>
                                </div>
                                <div>
                                <table class="table table-blue w-full">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span class="ml-2">
                                                        SOCIO PRINCIPAL
                                                    </span>
                                                </td>
                                                <td>
                                                    <input class="border" type="text" v-model="selected.info_cualitativa.socio_principal">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="ml-2">
                                                        VENTAS CONSOLIDADAS DEL GRUPO
                                                    </span>
                                                </td>
                                                <td>
                                                    <input class="border" type="text" v-model="selected.info_cualitativa.ventas_consolidadas_del_grupo">
                                                </td>
                                            </tr>
                                        </tbody>
                                </table>
                                </div>
                            </div>
                            <div class="flex">
                                <button class="w-2/12 btn btn-green  font-bold mx-1" @click="saveCalculate">Aceptar</button>
                                <button class="w-2/12 btn btn-green  font-bold mx-1"  @click="$refs.modalCalculate.close">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ri-modal>
        <ri-modal ref="modalUpdated" size="lg:w-2/5 md:w-8/12">
            <template v-slot:body>
                <div class="info-modal">
                    <div class="info-modal__side">
                        <div>
                            <img src="/imgs/info.png" alt="">
                        </div>
                        <p class="mt-5">
                            Información
                        </p>
                    </div>
                    <div class="px-3 py-1 info-modal__content">
                        <h2 class="info-modal__title">Rating Empresas</h2>

                        <p class="mt-8">ITAD007 MODIFICACION EFECTUADA</p>

                        <div class="info-modal__fotter px-3 py-5">
                            <button class="btn btn-green font-bold px-5" @click="$refs.modalUpdated.close">Cerrar</button>
                        </div>
                    </div>
                </div>
            </template>
        </ri-modal>
        <ri-modal ref="modalSaved" size="lg:w-2/5 md:w-8/12">
            <template v-slot:body>
                <div class="info-modal">
                    <div class="info-modal__side">
                        <div>
                            <img src="/imgs/info.png" alt="">
                        </div>
                        <p class="mt-5">
                            Información
                        </p>
                    </div>
                    <div class="px-3 py-1 info-modal__content">
                        <h2 class="info-modal__title">Rating Empresas</h2>

                        <p class="mt-8">XGA0509 OPERACIÓN REALIZADA CON EXITO</p>

                        <div class="info-modal__fotter px-3 py-5">
                            <button class="btn btn-green font-bold px-5" @click="goToRatingList">Cerrar</button>
                        </div>
                    </div>
                </div>
            </template>
        </ri-modal>
        <ri-modal ref="modalRating" size="lg:w-2/5 md:w-8/12">
            <template v-slot:body>
                <div class="info-modal">
                    <div class="info-modal__side">
                        <div>
                            <img src="/imgs/info.png" alt="">
                        </div>
                        <p class="mt-5">
                            Información
                        </p>
                    </div>
                    <div class="px-3 py-1 info-modal__content">
                        <h2 class="info-modal__title">Descripción</h2>

                        <p class="mt-8">RATING EMPRESAS CAPTURA DE INFORMACION &lt;&equals; 50 MILL DLLS </p>

                        <div class="info-modal__fotter px-3 py-5">
                            <button class="btn btn-green font-bold px-5" @click="$refs.modalRating.close">Cerrar</button>
                        </div>
                    </div>
                </div>
            </template>
        </ri-modal>
    </div>
</template>
<style lang="scss" scoped>
.btn-actions:focus {
    & + .btn-actions__menu{
        display: block;
    }
}
.btn-actions__menu{
    position: absolute;
    top: 100%;
    background: #fff;
    z-index: 2;
    display: none;
    &-item{
        display: block;
        background-color: #fff;
        color: #004580;
        border: 1px solid #58a1c5;
        width: 100%;
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0;
        line-height: 1rem;
        height: 1.5rem;
        text-align: left;
        display: flex;
        align-items: center;
        padding-right: 1rem;
    }
    &-icon{
        background-color: #58a1c5;
        color: #fff;
        height: 1rem;
        width: 1rem;
        text-align: center;
        border-radius: 3px;
        display: inline-block;
        margin-right: 0.25rem;
    }
}
.text-primary {
    color: #004580;
}
.exercise__header{
    background-color: #e0eef3;
    color: #000000;
}
.search-results{
    &__header{
        height: 2rem;
    }
    &__info{
        display: flex;
        align-items: center;
        color: #004580;
        font-weight: bold;
        border: 1px solid #004580;
        margin-bottom: 2rem;
        img{
            width: 35px;
        }
    }
    &__content{
        height: 40vh;
    }
    &__table{
        width: 100%;
        thead{
            tr{
                th{
                    color: #818181;
                }
            }
        }
        tbody{
            tr{
                td{
                    background-color: #efefef;
                    color: #1b1b1b;
                }
            }
        }
    }
    &__controls{
        display: flex;
    }
}
.form_search_client{
    select,
    input{
        font-size: 0.8rem;
        height: 25px;
        border: 1px solid #ccc;
    }
}
.table-home{
    tr{
        td{
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }
    }
    &__title{
        color: #056fb6;
        font-weight: bold;
    }
}

.home-tabs{
    display: flex;
    .home-tab{
        background-color:#004580;
        color: #fff;
        margin-right: 0.25rem;
        padding: 0.25rem 1rem;
        border-radius: 0.25rem 0.25rem  0 0;
        min-width: 120px;
        &.home-tab--active{
            background-color: #b0cbe2;
            font-weight: bold;
        }
    }
}

.header__client{
    border: 2px solid #004580;
    width: 35%;
    background-color: #f1f1f1;
    padding: 0.1rem 0.25rem;
    &-menu{
        display: flex;
        color: #004580;
        font-size: 0.9rem;
        & > li{
            border-right: 1px solid #ccc;
            position: relative;
            line-height: 0.9rem;
            cursor: pointer;
            padding: 0 0.2rem;
            & > ul {
                position: absolute;
                background: #fff;
                white-space: nowrap;
                left: 0;
                top: 100%;
                box-shadow:  2px 0 0 rgba($color: #000000, $alpha: 0.3);
                display: none;
                z-index: 99;
                &> li {
                    padding: 0.1rem 0.2rem;
                    line-height: 0.8rem;
                    font-weight: 600;
                    cursor: pointer;
                    p{
                        margin: 0;
                        line-height: 0.9rem;
                    }
                    &:hover{
                        background-color: #6097c6;
                        color: #fff;
                    }
                }
            }
            &:hover{
                & > ul {
                    display: block;
                }
            }
        }
    }
}
.header__home{
    display: flex;
    &-l{
        width: 65%;
        &-bottom,
        &-top{
            display: flex;
        }
    }
    &-logo{
        width: 70%;
        display: flex;
        white-space: nowrap;
        align-items: flex-end;
        font-size: 1.2rem;
        color: #004580;
        img{
            width: 30%;
        }
    }
    &-actions{
        background-color: #ececec;
        border: 1px solid #d3d2d2;
        color: #004580;
        align-self: flex-start;
        padding: 0.25rem 0.5rem;
        margin-top: 0.5rem;
        font-size: 0.9rem;
    }
}
.info-modal{
    display: flex;
    height: 350px;
    &__side{
        width: 30%;
        background-color: #e6f2fb;
        height: 100%;
        text-align: center;
        img{
            width: 80px;
            display: inline-block;
        }
    }
    &__title{
        color: #056fb6;
        font-weight: 600;
        font-size: 1.1rem;
    }
    &__content{
        position: relative;
        width: 80%;
    }
    &__fotter{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.header{
    &__light-blue{
        background-color: #b0cbe2;
        height: 2rem;
    }
    &__dark-blue{
        background-color: #6395c4;
        height: 2rem;
    }
}

$modal_color: #0078ff;
.ex-modal{
    &__wrap{
        border: 2px solid $modal_color;
        min-height: 50vh;
    }
    &__header{
        background: $modal_color;
        color: #fff;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 0.25rem;
        position: relative;
        &-close{
            position: absolute;
            right: 1rem;
            top: 0.25rem;
            background: #0040a6;
            width: 1rem;
            height: 1rem;
            padding: 0;
            line-height: 1rem;
        }
    }
}
</style>