export default [
    {
        id: 1,
        bbvaid: 'MX007401767691',
        basic: {
            periodo: '2021-06-30',
            meses: 12,
            tipo_entrada: 'Históricos',
            estado: 'Cerrado',
            tipo_de_estado: 'Anual',
            estandar_contable: 'US-GAAP',
            marca_de_ajuste: 'No Ajustado',
            certificacion: 'Fiscal',
            tributacion: 'Impuesto de Sociedades',
            estructura_productiva: 'Ventas al por mayor',
            nombre_auditor: '',
            opinion_de_la_auditoria: '',
            comentarios: '',
            numero_de_empleado: 60,
            marca_de_conciliacion: '',
            usuario_confimacion: '',
            fecha_de_confirmacion: '',
            usuario_cierre: 'm58781',
            fecha_cierre: '2022-09-30',
            tipo_balance: 'Individual',
            moneda: 'MXN Pesos de México',
            reformulado: '',
            estado_proyeccion_id: '542659',
            conciliar_con: 'NINGUNO',
            total_de_activos: 669887,
            total_pasivos: 669887,
            diferencia: 0,
        },
        activo_circulante: {
            activo_circulante: 403232,
            caja_y_bancos: 66177,
            depositos_en_garantias: 0,
            valores_negociables: 0,
            clientes: 197137,
            ctas_por_cobrar_soc_relacionada: 543,
            adeudado_por_emp_rel_pc: 0,
            ctas_por_cobrar_diversas: 0,
            prov_cobranzas_dudosa_clientes: 0,
            meteria_prima_envases_y_suministros: 0,
            existencias_por_recibir: 0,
            productos_en_proceso: 0,
            mercanderias_productos_terminados: 56015,
            subprodutos_desechos_y_desperdicios: 0,
            prov_por_desvalorizacion_de_existencia: 0,
            anticipos_a_proveedores: 0,
            suministros: 0,
            otras_existencias: 0,
            total_inventario: 56015,
            coste_x_sobrefacturacion: 0,
            impuestos_acreditables: 29045,
            impuestos_acreditables_diferidos: 0,
            cargas_diferidas: 0,
            otros_activos_corrientes: 54315,
            activos_derivados_corrientes: 0,
            caja_no_disponible_o_prendada: 0,
            activos_ciculantes_no_op: 0,
            
        },
        activos_no_circulantes: {
            activos_no_circulantes: 266655,
            terrenos: 0,
            trabajos_en_curso: 0,
            edificios_y_otras_construcciones: 0,
            maquinaria_y_equipo: 0,
            mejoras_en_rentas: 0,
            equipos_diversos: 0,
            muebles_y_enseres: 0,
            unidades_de_transporte: 0,
            rentas_de_capital: 0,
            mayor_valor_por_retasacion_tecnica: 0,
            otros_activos_fijos: 0,
            activo_fijo_bruto: 0,
            depreciacion_acumulada: 0,
            total_activo_fijo_neto: 0,
            ctas_por_cobrar_soc_relacionada: 0,
            otras_ctas_por_cobrar: 0,
            valor_efvo_seguro_vida: 0,
            inversiones_en_relacionadas: 0,
            provision_inmovilizado_financiero: 0,
            prestamos_a_emp_rel_plp: 0,
            efectivo_restringido: 0,
            otras_inversiones: 0,
            cargas_diferidas: 0,
            otros_activos_no_corrientes: 0,
            impuestos_recuperables_diferidos: 0,
            activos_derivados_plp: 0,
            activos_no_circ_no_op: 0,
            derechos_de_leasing: 0,
            fondo_de_comercio: 0,
            intangibles: 0,
            intangibles_brutos: 0
        }

    }
]