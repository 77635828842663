export default [{
    id: 1,
    bbvaid: 'MX007401767691',
    bbvaid_manual: 'MX007401767691',
    nombre: 'INDUSTRIAS TEBI',
    tipo: 'SA DE CV',
    nombre_largo: 'INDUSTRIAS TEBI SA DE CV',
    numero_cliente: '17493614',
    domiciolio_basico: 'CALLE 5',
    homoclave: '3C6',
    sector: 'PERSONAS MORALES',
    segmento: 'EMPRESARIA',
    solicitado: false,
    exacto: false,
    ejercicios: [
        {
            id: 1,
            desde: '01-2022',
            hasta: '12-2022',
            estudio_asoc: 'AR EMPRESARIAL INDUSTRIA S1',
            ajuste: 'N',
            certificacion: 'INFORMACION INTERNA',
            st: 'c',
            acciones: 'Acciones', 
            segmento: '',
            en: 'MILES',
            tribulacion: 'REGIMEN GENERAL',
            estructura_prod: 'HIL. Y TEJ. FIB. BLANDAS',
            estado: 'CIUDAD DE MEXICO',
            num_empleados: '300',
            nombre: '',
            procedencia: '',
            salvedades: '',
            descripcion_de_salvedades: '',
            info_economica: {
                activo: {
                    activo: 0,
                    activo_circulante: {
                        activo_ciculante: 0,
                        caja_y_bancos: 0,
                        inversiones_temporales: 0,
                        clientes_y_doctos_x_cobrar: 0,
                        inventarios: 0,
                        cuentas_por_cobrar_a_filiales: 0,
                        pagos_anticipados: 0,
                        otros_activos_circ_operativos: 0,
                        deudores_diversos: 0,
                        otros_activos_circulantes_no_operativos: 0,
                    },
                    activo_fijo: {
                        activo_fijo: 0,
                        propiedades_planta_y_equipo_neto: 0,
                        inversiones_en_subsidiarias_afiliado: 0,
                        cuentas_por_cobrar_afililares: 0,
                        otros_activos_fijos: 0,
                    },
                    otros_activos: {
                        otros_activos: 0,
                        activo_por_impuesto_diferido: 0,
                        patentes_licencias_y_marcas: 0,
                        credito_mercantil: 0,
                        cargos_diferidos: 0,
                    }
                },
                pasivo: {
                    pasivo_corto_plazo: {
                        pasivo_corto_plazo: 0,
                        bancomer_corto_plazo: 0,
                        otros_bancos_corto_plazo: 0,
                        emisiones_de_deuda: 0,
                        porc_circ_largo_plazo_bancom: 0,
                        porc_circ_largo_plazo_otros_b: 0,
                        proveedores: 0,
                        impuestos_por_pagar: 0,
                        cuentas_por_pagar_filiales: 0,
                        gastos_acumulados: 0,
                        otros_pasivos_circ_operativos: 0,
                        otros_pasivos_circ_no_operativos: 0,
                    },
                    pasivo_largo_plazo: {
                        pasivo_largo_plazo: 0,
                        bancomer_largo_plazo: 0,
                        otros_bancos_largo_plazo: 0,
                        emisiones_de_deuda: 0,
                        cuentas_por_pagar_filiales: 0,
                        otros_pasivos_largo_plazo: 0,
                        primas_de_antiguedad: 0,
                        pasivos_diferidos: 0,
                        pasivos_por_impuestos_diferidos: 0,
                    },
                    capital_contable: {
                        capital_contable: 0,
                        capital_social_actualizado: 0,
                        aportaciones_pendiente_captital: 0,
                        prima_de_emisiones_de_acciones: 0,
                        interes_minoritario: 0,
                        exc_insuf_actualizado_cap_c: 0,
                        impac_isr_diferido: 0,
                        reservas: 0,
                        utilidades_retenidas: 0,
                        utilidad_del_ejercicio: 0,
                    }
                },
                cuenta_de_resultados: {
                    ventas_netas_exportacion: 0,
                    ventas_netas_nacionales: 0,
                    ventas_netas_totales: 0,
                    costo_de_ventas: 0,
                    utilida_bruta: 0,
                    amortizacion: 0,
                    depreciacion: 0,
                    gastos_de_venta_y_administracion: 0,
                    uafir: 0,
                    perdida_utilidad_cambiaria: 0,
                    perdida_utilidad_monetaria: 0,
                    productos_financieros: 0,
                    gastos_financieros: 0,
                    costo_integral_de_financiamiento: 0,
                    otros_gastos_ingresos: 0,
                    utilidad_antes_de_isr_y_ptu: 0,
                    impuestos_y_ptu_diferido: 0,
                    impac_causado: 0,
                    ptu_causado: 0,
                    isr_causado: 0,
                    utilidad_antes_de_part_extraordinaria: 0,
                    perdida_utilidad_subafiliada: 0,
                    perdida_utilidad_extraordinaria: 0,
                    utiliad_neta: 0,
                },
                conciliaciones: {
                    inversiones_netas_en_inventarios: 0,
                    inversion_subs_af: 0,
                    dividendos_subs_af: 0,
                    inv_neta_en_prop_pta_equipo: 0,
                    aportacion_accionista_en_efectivo: 0,
                    dividentos_pagados_en_efectivo: 0,
                    perd_ut_cambiaria_cp: 0,
                    perd_ut_cambiaria_lp: 0,
                    perd_ut_cambiaria_en_clientes: 0,
                    perd_ut_cambiaria_en_inventarios: 0,
                    perd_ut_cambiaria_en_proveedores: 0,
                    exportaciones: 0,
                    importaciones: 0,
                    porc_circ_lp_otra_divisa: 0,
                    otros_gastos_ng_en_efectivo: 0,
                    perd_ut_extraord_en_efectivo: 0,
                }
            }
        }
    ],
    analisis: [
        {
            id: 1,
            periodo: 'A',
            ejercicio: '200512',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'INFORMACION INTERNA',
            f_analisis: '2006-09-13',
        },
        {
            id: 2,
            periodo: 'A',
            ejercicio: '200612',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'AUDITADA C/ SALVEDAD',
            f_analisis: '2007-09-25',
        },
        {
            id: 3,
            periodo: 'A',
            ejercicio: '200712',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'INFORMACION INTERNA',
            f_analisis: '2008-10-01',
        },
        {
            id: 4,
            periodo: 'A',
            ejercicio: '200812',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'INFORMACION INTERNA',
            f_analisis: '2009-10-01',
        },
        
    ],
    ratings: [
        {
            id: 1,
            status: 'FINALIZADO',
            calif: 'BB1',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2022-10-05',
            ult_ejer_cerrado: '06-2022',
            pf_cuant: '0041.91',
            pf_cual: '0011.24',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 2,
            status: 'FINALIZADO',
            calif: 'BB2',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2021-10-14',
            ult_ejer_cerrado: '09-2021',
            pf_cuant: '0040.67',
            pf_cual: '0011.24',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 3,
            status: 'FINALIZADO',
            calif: 'BB2',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2021-09-13',
            ult_ejer_cerrado: '07-2021',
            pf_cuant: '0039.84',
            pf_cual: '0011.24',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 4,
            status: 'FINALIZADO',
            calif: 'BB-2',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2020-12-11',
            ult_ejer_cerrado: '07-2020',
            pf_cuant: '0035.96',
            pf_cual: '0011.31',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 5,
            status: 'FINALIZADO',
            calif: 'B+3',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2020-10-15',
            ult_ejer_cerrado: '07-2020',
            pf_cuant: '0033.17.96',
            pf_cual: '011.31',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
    ]

}, {
    id: 5,
    bbvaid: 'MX007401767695',
    bbvaid_manual: 'MX007401767695',
    nombre: 'CITRICOS DEL SUR',
    tipo: 'SA DE CV',
    nombre_largo: 'CITRICOS DEL SUR SA DE CV',
    numero_cliente: '17493678',
    domiciolio_basico: 'CALLE',
    homoclave: '4C7',
    sector: 'PERSONAS MORALES',
    segmento: 'EMPRESARIA',
    solicitado: false,
    exacto: false,
    ejercicios: [
        {
            id: 1,
            desde: '01-2020',
            hasta: '12-2020',
            estudio_asoc: 'AR EMPRESARIAL INDUSTRIA S1',
            ajuste: 'N',
            certificacion: 'INFORMACION INTERNA',
            st: 'c',
            acciones: 'Acciones', 
            segmento: '',
            en: 'MILES',
            tribulacion: 'REGIMEN GENERAL',
            estructura_prod: 'HIL. Y TEJ. FIB. BLANDAS',
            estado: 'CIUDAD DE MEXICO',
            num_empleados: '300',
            nombre: '',
            procedencia: '',
            salvedades: '',
            descripcion_de_salvedades: '',
            info_economica: {
                activo: {
                    activo: 362350,
                    activo_circulante: {
                        activo_ciculante: 263572,
                        caja_y_bancos: 1455,
                        inversiones_temporales: 0,
                        clientes_y_doctos_x_cobrar: 77314,
                        inventarios: 43136,
                        cuentas_por_cobrar_a_filiales: 39802,
                        pagos_anticipados: 7138,
                        otros_activos_circ_operativos: 54116,
                        deudores_diversos: 40611,
                        otros_activos_circulantes_no_operativos: 0,
                    },
                    activo_fijo: {
                        activo_fijo: 93743,
                        propiedades_planta_y_equipo_neto: 52291,
                        inversiones_en_subsidiarias_afiliado: 21427,
                        cuentas_por_cobrar_afililares: 0,
                        otros_activos_fijos: 20025,
                    },
                    otros_activos: {
                        otros_activos: 5035,
                        activo_por_impuesto_diferido: 4139,
                        patentes_licencias_y_marcas: 0,
                        credito_mercantil: 0,
                        cargos_diferidos: 896,
                    }
                },
                pasivo: {
                    pasivo: 362350,
                    pasivo_corto_plazo: {
                        pasivo_corto_plazo: 257321,
                        bancomer_corto_plazo: 0,
                        otros_bancos_corto_plazo: 62000,
                        emisiones_de_deuda: 0,
                        porc_circ_largo_plazo_bancom: 0,
                        porc_circ_largo_plazo_otros_b: 13610,
                        proveedores: 67993,
                        impuestos_por_pagar: 5081,
                        cuentas_por_pagar_filiales: 3520,
                        gastos_acumulados: 0,
                        otros_pasivos_circ_operativos: 105117,
                        otros_pasivos_circ_no_operativos: 0,
                    },
                    pasivo_largo_plazo: {
                        pasivo_largo_plazo: 36546,
                        bancomer_largo_plazo: 0,
                        otros_bancos_largo_plazo: 36546,
                        emisiones_de_deuda: 0,
                        cuentas_por_pagar_filiales: 0,
                        otros_pasivos_largo_plazo: 0,
                        primas_de_antiguedad: 0,
                        pasivos_diferidos: 0,
                        pasivos_por_impuestos_diferidos: 0,
                    },
                    capital_contable: {
                        capital_contable: 68483,
                        capital_social_actualizado: 47939,
                        aportaciones_pendiente_captital: 0,
                        prima_de_emisiones_de_acciones: 0,
                        interes_minoritario: 0,
                        exc_insuf_actualizado_cap_c: 0,
                        impac_isr_diferido: 0,
                        reservas: 0,
                        utilidades_retenidas: -2571,
                        utilidad_del_ejercicio: 23115,
                    }
                },
                cuenta_de_resultados: {
                    ventas_netas_exportacion: 0,
                    ventas_netas_nacionales: 404346,
                    ventas_netas_totales: 404346,
                    costo_de_ventas: 226269,
                    utilida_bruta: 178077,
                    amortizacion: 0,
                    depreciacion: 2487,
                    gastos_de_venta_y_administracion: 145300,
                    uafir: 30290,
                    perdida_utilidad_cambiaria: 1222,
                    perdida_utilidad_monetaria: 0,
                    productos_financieros: 0,
                    gastos_financieros: 12144,
                    costo_integral_de_financiamiento: 13366,
                    otros_gastos_ingresos: -6191,
                    utilidad_antes_de_isr_y_ptu: 23115,
                    impuestos_y_ptu_diferido: 0,
                    impac_causado: 0,
                    ptu_causado: 0,
                    isr_causado: 0,
                    utilidad_antes_de_part_extraordinaria: 23115,
                    perdida_utilidad_subafiliada: 0,
                    perdida_utilidad_extraordinaria: 0,
                    utiliad_neta: 23115,
                },
                conciliaciones: {
                    inversiones_netas_en_inventarios: 12678,
                    inversion_subs_af: 0,
                    dividendos_subs_af: 0,
                    inv_neta_en_prop_pta_equipo: 6443,
                    aportacion_accionista_en_efectivo: 0,
                    dividentos_pagados_en_efectivo: 0,
                    perd_ut_cambiaria_cp: 15152,
                    perd_ut_cambiaria_lp: 0,
                    perd_ut_cambiaria_en_clientes: 0,
                    perd_ut_cambiaria_en_inventarios: 0,
                    perd_ut_cambiaria_en_proveedores: 0,
                    exportaciones: 0,
                    importaciones: 0,
                    porc_circ_lp_otra_divisa: 0,
                    otros_gastos_ng_en_efectivo: 0,
                    perd_ut_extraord_en_efectivo: 0,
                }
            }
        },
        {
            id: 2,
            desde: '01-2021',
            hasta: '12-2021',
            estudio_asoc: 'AR EMPRESARIAL INDUSTRIA S1',
            ajuste: 'N',
            certificacion: 'INFORMACION INTERNA',
            st: 'c',
            acciones: 'Acciones', 
            segmento: '',
            en: 'MILES',
            tribulacion: 'REGIMEN GENERAL',
            estructura_prod: 'HIL. Y TEJ. FIB. BLANDAS',
            estado: 'CIUDAD DE MEXICO',
            num_empleados: '300',
            nombre: '',
            procedencia: '',
            salvedades: '',
            descripcion_de_salvedades: '',
            info_economica: {
                activo: {
                    activo: 355658,
                    activo_circulante: {
                        activo_ciculante: 257531,
                        caja_y_bancos: 16765,
                        inversiones_temporales: 0,
                        clientes_y_doctos_x_cobrar: 73188,
                        inventarios: 55814,
                        cuentas_por_cobrar_a_filiales: 32902,
                        pagos_anticipados: 4517,
                        otros_activos_circ_operativos: 20899,
                        deudores_diversos: 56446,
                        otros_activos_circulantes_no_operativos: 0,
                    },
                    activo_fijo: {
                        activo_fijo: 96958,
                        propiedades_planta_y_equipo_neto: 55506,
                        inversiones_en_subsidiarias_afiliado: 21427,
                        cuentas_por_cobrar_afililares: 0,
                        otros_activos_fijos: 20025,
                    },
                    otros_activos: {
                        otros_activos: 1169,
                        activo_por_impuesto_diferido: 0,
                        patentes_licencias_y_marcas: 0,
                        credito_mercantil: 0,
                        cargos_diferidos: 1169,
                    }
                },
                pasivo: {
                    pasivo: 355658,
                    pasivo_corto_plazo: {
                        pasivo_corto_plazo: 248741,
                        bancomer_corto_plazo: 0,
                        otros_bancos_corto_plazo: 77000,
                        emisiones_de_deuda: 0,
                        porc_circ_largo_plazo_bancom: 0,
                        porc_circ_largo_plazo_otros_b: 13858,
                        proveedores: 52775,
                        impuestos_por_pagar: 6411,
                        cuentas_por_pagar_filiales: 4273,
                        gastos_acumulados: 0,
                        otros_pasivos_circ_operativos: 94424,
                        otros_pasivos_circ_no_operativos: 0,
                    },
                    pasivo_largo_plazo: {
                        pasivo_largo_plazo: 21123,
                        bancomer_largo_plazo: 0,
                        otros_bancos_largo_plazo: 21123,
                        emisiones_de_deuda: 0,
                        cuentas_por_pagar_filiales: 0,
                        otros_pasivos_largo_plazo: 0,
                        primas_de_antiguedad: 0,
                        pasivos_diferidos: 0,
                        pasivos_por_impuestos_diferidos: 0,
                    },
                    capital_contable: {
                        capital_contable: 85794,
                        capital_social_actualizado: 47939,
                        aportaciones_pendiente_captital: 0,
                        prima_de_emisiones_de_acciones: 0,
                        interes_minoritario: 0,
                        exc_insuf_actualizado_cap_c: 0,
                        impac_isr_diferido: 0,
                        reservas: 0,
                        utilidades_retenidas: 20543,
                        utilidad_del_ejercicio: 17312,
                    }
                },
                cuenta_de_resultados: {
                    ventas_netas_exportacion: 0,
                    ventas_netas_nacionales: 495444,
                    ventas_netas_totales: 495444,
                    costo_de_ventas: 327963,
                    utilida_bruta: 167481,
                    amortizacion: 0,
                    depreciacion: 3228,
                    gastos_de_venta_y_administracion: 131661,
                    uafir: 32592,
                    perdida_utilidad_cambiaria: 15152,
                    perdida_utilidad_monetaria: 0,
                    productos_financieros: 0,
                    gastos_financieros: 7526,
                    costo_integral_de_financiamiento: 22678,
                    otros_gastos_ingresos: -7398,
                    utilidad_antes_de_isr_y_ptu: 17312,
                    impuestos_y_ptu_diferido: 0,
                    impac_causado: 0,
                    ptu_causado: 0,
                    isr_causado: 0,
                    utilidad_antes_de_part_extraordinaria: 17312,
                    perdida_utilidad_subafiliada: 0,
                    perdida_utilidad_extraordinaria: 0,
                    utiliad_neta: 17312,
                },
                conciliaciones: {
                    inversiones_netas_en_inventarios: 12678,
                    inversion_subs_af: 0,
                    dividendos_subs_af: 0,
                    inv_neta_en_prop_pta_equipo: 6443,
                    aportacion_accionista_en_efectivo: 0,
                    dividentos_pagados_en_efectivo: 0,
                    perd_ut_cambiaria_cp: 15152,
                    perd_ut_cambiaria_lp: 0,
                    perd_ut_cambiaria_en_clientes: 0,
                    perd_ut_cambiaria_en_inventarios: 0,
                    perd_ut_cambiaria_en_proveedores: 0,
                    exportaciones: 0,
                    importaciones: 0,
                    porc_circ_lp_otra_divisa: 0,
                    otros_gastos_ng_en_efectivo: 0,
                    perd_ut_extraord_en_efectivo: 0,
                }
            }
        },
    ],
    analisis: [
        {
            id: 1,
            periodo: 'A',
            ejercicio: '200512',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'INFORMACION INTERNA',
            f_analisis: '2006-09-13',
        },
        {
            id: 2,
            periodo: 'A',
            ejercicio: '200612',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'AUDITADA C/ SALVEDAD',
            f_analisis: '2007-09-25',
        },
        {
            id: 3,
            periodo: 'A',
            ejercicio: '200712',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'INFORMACION INTERNA',
            f_analisis: '2008-10-01',
        },
        {
            id: 4,
            periodo: 'A',
            ejercicio: '200812',
            modelo: '7001',
            descripcion: 'EMPRESARIAL MEDIO CONTRASTE',
            certificacion: 'INFORMACION INTERNA',
            f_analisis: '2009-10-01',
        },
        
    ],
    ratings: [
        {
            id: 1,
            status: 'FINALIZADO',
            calif: 'BB1',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2022-10-05',
            ult_ejer_cerrado: '06-2022',
            pf_cuant: '0041.91',
            pf_cual: '0011.24',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 2,
            status: 'FINALIZADO',
            calif: 'BB2',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2021-10-14',
            ult_ejer_cerrado: '09-2021',
            pf_cuant: '0040.67',
            pf_cual: '0011.24',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 3,
            status: 'FINALIZADO',
            calif: 'BB2',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2021-09-13',
            ult_ejer_cerrado: '07-2021',
            pf_cuant: '0039.84',
            pf_cual: '0011.24',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 4,
            status: 'FINALIZADO',
            calif: 'BB-2',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2020-12-11',
            ult_ejer_cerrado: '07-2020',
            pf_cuant: '0035.96',
            pf_cual: '0011.31',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
        {
            id: 5,
            status: 'FINALIZADO',
            calif: 'B+3',
            t_analisis: 'EMPRESARIAL INDUSTRI',
            f_analisis: '2020-10-15',
            ult_ejer_cerrado: '07-2020',
            pf_cuant: '0033.17.96',
            pf_cual: '011.31',
            nv_aler_expl: '0002',
            nv_aler_cond: '0000',
            sucursal: '0000',
            usuario: 'MB00000',
        },
    ]

}, {
    id: 2,
    bbvaid: 'MX007401767692',
    bbvaid_manual: 'MX007401767691',
    nombre: 'SORIANO ARIZA Y ASOCIADOS',
    tipo: 'SA DE CV',
    nombre_largo: 'SORIANO ARIZA Y ASOCIADOS SA DE CV',
    numero_cliente: '17493615',
    domiciolio_basico: 'GANTE 9',
    homoclave: '3C7',
    sector: 'PERSONAS MORALES',
    segmento: 'EMPRESARIA',
    solicitado: false,
    exacto: false,


}]