import Vue from 'vue'
import config from '@/data/config'
// export const setCurrent = (state, payload) => {
//     let currentClient = JSON.parse(JSON.stringify(state.clients.find(client => client.bbvaid == payload)))
//     let currentPeriods = JSON.parse(JSON.stringify(state.periods.filter(period => period.bbvaid == payload)))

// 	Vue.set(state.current, 'client', currentClient)
// 	Vue.set(state.current, 'periods', currentPeriods)

// 	Vue.set(state.staging, 'client', currentClient)
// 	Vue.set(state.staging, 'periods', currentPeriods)
// }

export const setSearchParams = (state, payload) => {
	Vue.set(state, 'searchParams', payload)
}
export const setCurrent = (state, payload) => {
	Vue.set(state, 'current', payload)
}

export const newExercise = (state, payload) => {
	let data = {
			id: Date.now(),
            desde: payload.desde,
            hasta: payload.hasta,
            estudio_asoc: '',
            ajuste: 'N',
            certificacion: payload.certificacion,
            st: 'P',
            acciones: 'Acciones', 
            segmento: payload.segmento,
            en: payload.en,
            tribulacion: payload.tribulacion,
            estructura_prod: payload.estructura_prod,
            estado: payload.estado,
            num_empleados: payload.num_empleados,
            nombre: payload.nombre,
            procedencia: payload.procedencia,
            salvedades: payload.salvedades,
            descripcion_de_salvedades: payload.descripcion_de_salvedades,
			info_economica: {...config.blanks.info_economica},
            info_cualitativa: {...config.blanks.info_cualitativa}
	}

	console.log('NW', data.info_cualitativa)

	let client = state.clients.find(c => c.id == state.current)

    
	if(client) {
		client.ejercicios.push(data)
	}

    localStorage.setItem('clients', JSON.stringify(state.clients))

}

export const updateExercise = (state, payload) => {
    let current = state.clients.find(c => c.id == state.current)

    let exerciseIndex = current.ejercicios.findIndex(e => e.id == payload.id)

    current.ejercicios[exerciseIndex] = { ...payload }

    localStorage.setItem('clients', JSON.stringify(state.clients))
}