let qualitative_items = [
    {
        key: 'vulnerabilidad_al_tipo_de_cambio',
        label: 'VULNERABILIDAD AL TIPO DE CAMBIO',
        options: [
            'PASIVOS EN M.E. SIN/COBERTURA',
            'PASIVOS EN M.E CON COBERTURA',
            'NO MANEJA OTRAS DIVISAS'
        ]
    },
    {
        key: 'regulaciones_oficiales',
        label: 'REGULACIONES OFICIALES',
        options: [
            'OFICIALES AFECTAN LA OPERACION',
            'NO TIENE IMPLICACIONES',
        ]
    },
    {
        key: 'presencia_en_el_mercado',
        label: 'PRESENCIA EN EL MERCADO',
        options: [
            'INTERNACIONAL GLOBAL, INTERNACIONAL REGIONAL',
            'NACIONAL',
            'LOCAL, REGIONAL',
        ]
    },
    {
        key: 'relacion_con_proveedores',
        label: 'RELACION CON PROVEEDORES',
        options: [
            'DIVERSIFICACION DE PROVEEDORES',
            'CONCENTRACION DE PROVEEDORES',
            'DEPENDENCIA DE PROVEEDORES',
        ]
    },
    {
        key: 'relacion_con_clientes',
        label: 'RELACION CON CLIENTES',
        options: [
            'DIVERSIFICACION DE CLIENTES',
            'CONCENTRACION DE CLIENTES',
            'DEPENDENCIA DE CLIENTES',
        ]
    },
    {
        key: 'experiencia_de_pago',
        label: 'EXPERIENCIA DE PAGO',
        options: [
            'AL CORRIENTE',
            'CARTERA VENCIDA TRANSPORTISTA',
            'CARTERA EN PROCESO DE RECUPERACION',
        ]
    },
    {
        key: 'agresividad_toma_de_decisiones_de_riesgos',
        label: 'AGRESIVIDAD TOMA DE DECISIONES DE RIESGO',
        options: [
            'AGRESIVO',
            'MEDIO',
            'CONSERVADOR',
        ]
    },
    {
        key: 'accionistas_con_voto',
        label: 'ACCIONISTAS (CON VOTO)',
        options: [
            'SIN CAMBIO EN EL ACCIONARIO',
            'CON CAMBIOS EN EL ACCIONARIO',
        ]
    },
    {
        key: 'auditorias_ultimo_ejercicio',
        label: 'AUDITORIA ULTIMO EJERCICIO',
        options: [
            'EEFF AUD. C/DICT FAVORABLE',
            'DESFAVORABLE, AUD. C/SALV O FISCAL',
            'AUD. DENEGADA O NO AUDT.',
        ]
    },
    {
        key: 'nivel_de_exportaciones',
        label: 'NIVEL DE EXPORTACIONES',
        options: [
            'EXP. ENTRE EL 1% Y EL 15%',
            'EXP. ENTRE EL 15.1% Y EL 30%',
            'EXP. ENTRE EL 30.1% Y EL 70%',
            'EXPORTA MAS DE 70%',
            'NO EXPORTA',
        ]
    },
    {
        key: 'estado_de_las_instalaciones',
        label: 'ESTADO DE LAS INSTALACIONES',
        options: [
            'ACTUALIZADOS',
            'OBSOLETAS',
            'NO SABE',
        ]
    },
    {
        key: 'destino_de_los_fondos_del_socio',
        label: 'DESTINO DE LOS FONDOS DEL SOCIO',
        options: [
            'INCREMENTA PATRIMONIO',
            'REINVIERTE EN LA EMPRESA',
            'OTROS TIPOS DE INVERSIONES',
            'NO SABE',
        ]
    },
    {
        key: 'numero_de_productos_o_servicios',
        label: 'NUMERO DE PRODUCTOS O SERVICIOS',
        options: [
            'UN SOLO PRODUCTO/SERVICIO',
            'BAJA DIVERSIFICACION, INICA LINEA C/VARIOS PRODUCTOS/SERVICIO',
            'ALTA DIVERSIFICACION, INICA LINEA C/VARIOS PRODUCTOS/SERVICIO',
            'NO SABE',
        ]
    },
    {
        key: 'grupo',
        label: 'GRUPO',
        options: [
            'PERTENECE A UN GRUPO ECONOMICO',
            'NO PERTENECE A UN GRUPO ECONOMICO',
        ]
    },
    {
        key: 'tipo_de_apoyo',
        label: 'TIPO DE APOYO',
        options: [
            'NO TIENE APOYO/MATRIZ/SOCIOS/PATRIMONIAL',
            'GARANTIA MATRIZ',
            'GARANTIA SOCIO',
            'PATRIMONIAL',
            'HIPOTECA DE MAXIMOS',
            'NO SABE',
        ]
    },
    {
        key: 'numero_de_bancos_con_los_que_trabaja',
        label: 'NUMERO DE BANCOS CON LOS QUE TRABAJA',
        options: [
            'HASTA CON 4',
            'DE 5 A 10',
            'CON MAS DE 10 ENTIDADES',
            'NO SABE',
        ]
    },
    {
        key: 'necesidades_finacieras_de_la_empresa',
        label: 'NECESIDADES FINANCIERAS DE LA EMPRESA',
        options: [
            'ALTAS NECESIDADES DE FINANCIACION',
            'NECESIDADES MEDIAS DE FINANCIACION',
            'BAJA NECESIDADES DE FINANCIERAS',
            'NO SABE',
        ]
    },
    {
        key: 'sector_asset_allocation',
        label: 'SECTOR ASSET ALLOCATION',
        options: [
            'CUSTOMER & HEALTHCARE, TRANSPORTATION',
        ]
    },
]
let analisis = {}

qualitative_items.forEach(item => analisis[item.key] = '')


export default {
    info_economica: {
        activo: {
            type: 'sum',
            label: 'ACTIVO',
            disabled: false,
            required: false,
            items: '*',
            children: {
                activo_circulante: {
                    type: 'sum',
                    label: 'ACTIVO CICULANTE',
                    disabled: false,
                    required: false,
                    items: '*',
                    children: {
                        caja_y_bancos: {
                            type: 'number',
                            label: 'CAJA Y BANCOS',
                            disabled: false,
                            required: false,
                        },
                        inversiones_temporales: {
                            type: 'number',
                            label: 'INVERSIONES TEMPORALES',
                            disabled: false,
                            required: false,
                        },
                        clientes_y_doctos_x_cobrar: {
                            type: 'number',
                            label: 'CLIENTES Y DOCTOS X COB.',
                            disabled: false,
                            required: false,
                        },
                        inventarios: {
                            type: 'number',
                            label: 'INVENTARIOS',
                            disabled: false,
                            required: false,
                        },
                        cuentas_por_cobrar_a_filiales: {
                            type: 'number',
                            label: 'CUENTAS POR COBRAR A FILIALES',
                            disabled: false,
                            required: false,
                        },
                        pagos_anticipados: {
                            type: 'number',
                            label: 'PAGOS ANTICIPADOS',
                            disabled: false,
                            required: false,
                        },
                        otros_activos_circ_operativos: {
                            type: 'number',
                            label: 'OTROS ACTIVOS CIRC. OPERATIVOS',
                            disabled: false,
                            required: false,
                        },
                        deudores_diversos: {
                            type: 'number',
                            label: 'DEUDORES DIVERSOS',
                            disabled: false,
                            required: false,
                        },
                        otros_activos_circulantes_no_operativos: {
                            type: 'number',
                            label: 'OTROS ACTIVOS CIRC. NO OPERATIVOS',
                            disabled: false,
                            required: false,
                        },
                    }
                },
                activo_fijo: {
                    type: 'sum',
                    label: 'ACTIVO FIJO',
                    disabled: false,
                    required: false,
                    items: '*',
                    children: {
                        propiedades_planta_y_equipo_neto: {
                            type: 'number',
                            label: 'PROPIEDAD PLANTA Y EQUIPO NETO',
                            disabled: false,
                            required: false,
                        },
                        inversiones_en_subsidiarias_afiliado: {
                            type: 'number',
                            label: 'INVERSIONES EN SUBSIDIEARIAS/AFILIADO',
                            disabled: false,
                            required: false,
                        },
                        cuentas_por_cobrar_afililares: {
                            type: 'number',
                            label: 'CUENTAS POR COBRAR FILIALES',
                            disabled: false,
                            required: false,
                        },
                        otros_activos_fijos: {
                            type: 'number',
                            label: 'OTROS ACTIVOS FIJOS',
                            disabled: false,
                            required: false,
                        },
                    }
                },
                otros_activos: {
                    type: 'sum',
                    label: 'OTROS ACTIVOS',
                    disabled: false,
                    required: false,
                    items: '*',
                    children: {
                        activo_por_impuesto_diferido: {
                            type: 'number',
                            label: 'ACTIVO POR IMPUESTO DIFERIDO',
                            disabled: false,
                            required: false,
                        },
                        patentes_licencias_y_marcas: {
                            type: 'number',
                            label: 'PATENTES POR IMPUESTO DIFERIDO',
                            disabled: false,
                            required: false,
                        },
                        credito_mercantil: {
                            type: 'number',
                            label: 'CREDITO LICIENCIAS Y MARCAS',
                            disabled: false,
                            required: false,
                        },
                        cargos_diferidos: {
                            type: 'number',
                            label: 'CARGOS DIFERIDOS',
                            disabled: false,
                            required: false,
                        },
                    }
                },
            },
        },
        pasivo: {
            type: 'sum',
            label: 'PASIVO',
            disabled: false,
            required: false,
            items: '*',
            children: {
                pasivo_corto_plazo: {
                    type: 'sum',
                    label: 'PASIVO CORTO PLAZO',
                    disabled: false,
                    required: false,
                    items: '*',
                    children: {
                        bancomer_corto_plazo: {
                            type: 'number',
                            label: 'BANCOMER CORTO PLAZO',
                            disabled: false,
                            required: false,
                        },
                        otros_bancos_corto_plazo: {
                            type: 'number',
                            label: 'OTROS BANCOS CORTO PLAZO',
                            disabled: false,
                            required: false,
                        },
                        emisiones_de_deuda: {
                            type: 'number',
                            label: 'EMISIONES DE DEUDA',
                            disabled: false,
                            required: false,
                        },
                        porc_circ_largo_plazo_bancom: {
                            type: 'number',
                            label: 'PORC. CIRC. LARGO PLAZO BANCOM',
                            disabled: false,
                            required: false,
                        },
                        porc_circ_largo_plazo_otros_b: {
                            type: 'number',
                            label: 'PARC. CIRC. LARGO PLAZO OTROS B',
                            disabled: false,
                            required: false,
                        },
                        proveedores: {
                            type: 'number',
                            label: 'PROVEEDORES',
                            disabled: false,
                            required: false,
                        },
                        impuestos_por_pagar: {
                            type: 'number',
                            label: 'IMPUESTOS POR PAGAR',
                            disabled: false,
                            required: false,
                        },
                        cuentas_por_pagar_filiales: {
                            type: 'number',
                            label: 'CUENTAS OPR PAGAR FILIALES',
                            disabled: false,
                            required: false,
                        },
                        gastos_acumulados: {
                            type: 'number',
                            label: 'GASTOS ACUMULADOS',
                            disabled: false,
                            required: false,
                        },
                        otros_pasivos_circ_operativos: {
                            type: 'number',
                            label: 'OTROS PASOVOS CIRC. OPERATIVOS',
                            disabled: false,
                            required: false,
                        },
                        otros_pasivos_circ_no_operativos: {
                            type: 'number',
                            label: 'OTROS PASIVOS CIRC. NO OPERATIVOS',
                            disabled: false,
                            required: false,
                        },
                    }
                },
                pasivo_largo_plazo: {
                    type: 'sum',
                    label: 'PASIVO LAGO PLAZO',
                    disabled: false,
                    required: false,
                    items: '*',
                    children: {
                        bancomer_largo_plazo: {
                            type: 'number',
                            label: 'BANCOMER LARGO PLAZO',
                            disabled: false,
                            required: false,
                        },
                        otros_bancos_largo_plazo: {
                            type: 'number',
                            label: 'OTROS BANCOS LARGO PLAZO',
                            disabled: false,
                            required: false,
                        },
                        emisiones_de_deuda: {
                            type: 'number',
                            label: 'EMISIONES DE DEUDA',
                            disabled: false,
                            required: false,
                        },
                        cuentas_por_pagar_filiales: {
                            type: 'number',
                            label: 'CUENTAS POR PAGAR FILIALES',
                            disabled: false,
                            required: false,
                        },
                        otros_pasivos_largo_plazo: {
                            type: 'number',
                            label: 'OTROS PASIVOS LARGO PLAZO',
                            disabled: false,
                            required: false,
                        },
                        primas_de_antiguedad: {
                            type: 'number',
                            label: 'PRIMAS DE ANTIGUEDAD',
                            disabled: false,
                            required: false,
                        },
                        pasivos_diferidos: {
                            type: 'number',
                            label: 'PASIVOS DIFERIDOS',
                            disabled: false,
                            required: false,
                        },
                        pasivos_por_impuestos_diferidos: {
                            type: 'number',
                            label: 'PASIVOS POR IMPUESTOS DIFERIDOS',
                            disabled: false,
                            required: false,
                        },
                    }
                },
                capital_contable: {
                    type: 'sum',
                    label: 'CAPITAL CONTABLE',
                    disabled: false,
                    required: false,
                    items: '*',
                    children: {
                        capital_social_actualizado: {
                            type: 'number',
                            label: 'CAPITAL SOCIAL ACTUALIZADO',
                            disabled: false,
                            required: false,
                        },
                        aportaciones_pendiente_captital: {
                            type: 'number',
                            label: 'APORTACIONES PENDIENTE CAPITAL',
                            disabled: false,
                            required: false,
                        },
                        prima_de_emisiones_de_acciones: {
                            type: 'number',
                            label: 'PRIMA EN EMISION DE ACCIONES',
                            disabled: false,
                            required: false,
                        },
                        interes_minoritario: {
                            type: 'number',
                            label: 'INTERES MINORITARIO',
                            disabled: false,
                            required: false,
                        },
                        exc_insuf_actualizado_cap_c: {
                            type: 'number',
                            label: 'EXC(INSUF) ACTUALIZACION CAP. C',
                            disabled: false,
                            required: false,
                        },
                        impac_isr_diferido: {
                            type: 'number',
                            label: 'IMPAC I.S.R DIFERIDO',
                            disabled: false,
                            required: false,
                        },
                        reservas: {
                            type: 'number',
                            label: 'RESERVAS',
                            disabled: false,
                            required: false,
                        },
                        utilidades_retenidas: {
                            type: 'number',
                            label: 'UTILIDAD RETENIDAS',
                            disabled: false,
                            required: false,
                        },
                        utilidad_del_ejercicio: {
                            type: 'number',
                            label: 'UTILIDAD DEL EJERCICIO',
                            disabled: false,
                            required: false,
                        },
                    }
                },
            }
        },
        cuenta_de_resultados: {
            type: 'none',
            label: 'CUENTA DE RESULTADOS',
            disabled: false,
            required: false,
            items: '*',
            children: {
                ventas_netas_exportacion: {
                    type: 'number',
                    label: 'VENTAS NETAS EXPORTACION',
                    disabled: false,
                    required: false,
                },
                ventas_netas_nacionales: {
                    type: 'number',
                    label: 'VENTAS NETAS NACIONALES',
                    disabled: false,
                    required: false,
                },
                ventas_netas_totales: {
                    type: 'sum',
                    label: 'VENTAS NETAS TOTALES',
                    disabled: true,
                    required: false,
                    items: ['ventas_netas_exportacion', 'ventas_netas_nacionales']
                },
                costo_de_ventas: {
                    type: 'number',
                    label: 'COSTO DE VENTAS',
                    disabled: false,
                    required: false,
                },
                utilida_bruta: {
                    type: 'sum_sub',
                    label: 'UTILIDAD BRUTA',
                    disabled: false,
                    required: false,
                    sum: ['ventas_netas_totales'],
                    sub: ['costo_de_ventas']
                },
                amortizacion: {
                    type: 'number',
                    label: 'AMORTIZACION',
                    disabled: false,
                    required: false,
                },
                depreciacion: {
                    type: 'number',
                    label: 'DEPRECIACION',
                    disabled: false,
                    required: false,
                },
                gastos_de_venta_y_administracion: {
                    type: 'number',
                    label: 'GASTOS DE VENTA Y ADMINISTRACION',
                    disabled: false,
                    required: false,
                },
                uafir: {
                    type: 'sum_sub',
                    label: 'UAFIR',
                    disabled: false,
                    required: false,
                    sum: ['utilida_bruta'],
                    sub: ['amortizacion', 'depreciacion', 'gastos_de_venta_y_administracion']
                },
                perdida_utilidad_cambiaria: {
                    type: 'number',
                    label: 'PERDIDA (UTILIDAD) CAMBIARIA',
                    disabled: false,
                    required: false,
                },
                perdida_utilidad_monetaria: {
                    type: 'number',
                    label: 'PERDIDA (UTILIDAD) MENETARIA',
                    disabled: false,
                    required: false,
                },
                productos_financieros: {
                    type: 'number',
                    label: 'PRODUCTOS FINANCIEROS',
                    disabled: false,
                    required: false,
                },
                gastos_financieros: {
                    type: 'number',
                    label: 'GASTOS FINANCIEROS',
                    disabled: false,
                    required: false,
                },
                costo_integral_de_financiamiento: {
                    type: 'sum_sub',
                    label: 'COSTO INTEGRAL DE FINANCIAMIENTO',
                    disabled: false,
                    required: false,
                    sum: ['gastos_financieros', 'perdida_utilidad_cambiaria', 'perdida_utilidad_monetaria'],
                    sub: ['productos_financieros']
                },
                otros_gastos_ingresos: {
                    type: 'number',
                    label: 'OTROS GASTOS (INGRESOS)',
                    disabled: false,
                    required: false,
                },
                utilidad_antes_de_isr_y_ptu: {
                    type: 'sum_sub',
                    label: 'UTILIDAD ANTES DE ISR Y PTU',
                    disabled: false,
                    required: false,
                    sum: ['uafir'],
                    sub: ['costo_integral_de_financiamiento', 'otros_gastos_ingresos']
                },
                impuestos_y_ptu_diferido: {
                    type: 'number',
                    label: 'IMPUESTOS Y PTU DIFERIDO',
                    disabled: false,
                    required: false,
                },
                impac_causado: {
                    type: 'number',
                    label: 'IMPAC CAUSADO',
                    disabled: false,
                    required: false,
                },
                ptu_causado: {
                    type: 'number',
                    label: 'PTU CAUSADO',
                    disabled: false,
                    required: false,
                },
                isr_causado: {
                    type: 'number',
                    label: 'ISR CAUSADO',
                    disabled: false,
                    required: false,
                },
                utilidad_antes_de_part_extraordinaria: {
                    type: 'sum_sub',
                    label: 'UTILIDAD ANTES DE PART. EXTRAO',
                    disabled: false,
                    required: false,
                    sum: ['utilidad_antes_de_isr_y_ptu'],
                    sub: ['impuestos_y_ptu_diferido', 'impac_causado', 'ptu_causado', 'isr_causado']
                },
                perdida_utilidad_subafiliada: {
                    type: 'number',
                    label: 'PERDIDA (UTILIDAD) SUBS/AFILIA',
                    disabled: false,
                    required: false,
                },
                perdida_utilidad_extraordinaria: {
                    type: 'number',
                    label: 'PARDIDA (UTILIDAD) EXTRAORDINA',
                    disabled: false,
                    required: false,
                },
                utiliad_neta: {
                    type: 'sum_sub',
                    label: 'UTILIDAD NETA',
                    disabled: false,
                    required: false,
                    sum: ['utilidad_antes_de_part_extraordinaria'],
                    sub: ['perdida_utilidad_subafiliada', 'perdida_utilidad_extraordinaria']
                },
               
            }
        },
        conciliaciones: {
            type: 'none',
            label: 'CONCILIACIONES',
            disabled: false,
            required: false,
            children: {
                inversiones_netas_en_inventarios: {
                    type: 'number',
                    label: 'INVERSION NETA EN INVENTARIOS',
                    disabled: false,
                    required: false,
                },
                inversion_subs_af: {
                    type: 'number',
                    label: 'INVERSION SUBS/AF',
                    disabled: false,
                    required: false,
                },
                dividendos_subs_af: {
                    type: 'number',
                    label: 'DIVIDENDOS SUBS/AF',
                    disabled: false,
                    required: false,
                },
                inv_neta_en_prop_pta_equipo: {
                    type: 'number',
                    label: 'INV. NETA PROP. PTA EQUIPO',
                    disabled: false,
                    required: false,
                },
                aportacion_accionista_en_efectivo: {
                    type: 'number',
                    label: 'APORTACION ACCIONISTAS EN EFECTIVO',
                    disabled: false,
                    required: false,
                },
                dividentos_pagados_en_efectivo: {
                    type: 'number',
                    label: 'DIVIDENDOS PAGADOS EN EFECTIVO',
                    disabled: false,
                    required: false,
                },
                perd_ut_cambiaria_cp: {
                    type: 'number',
                    label: 'PERD(UT)CAMBIARIA CP',
                    disabled: false,
                    required: false,
                },
                perd_ut_cambiaria_lp: {
                    type: 'number',
                    label: 'PERD(UT)CAMBIARIA LP',
                    disabled: false,
                    required: false,
                },
                perd_ut_cambiaria_en_clientes: {
                    type: 'number',
                    label: 'PERD(UT)CAMBIARIA EN CLIENTES',
                    disabled: false,
                    required: false,
                },
                perd_ut_cambiaria_en_inventarios: {
                    type: 'number',
                    label: 'PERD(UT)CAMBIARIA EN INVENTARIOS',
                    disabled: false,
                    required: false,
                },
                perd_ut_cambiaria_en_proveedores: {
                    type: 'number',
                    label: 'PERD(UT)CAMBIARIA EN PROVEEDORES',
                    disabled: false,
                    required: false,
                },
                exportaciones: {
                    type: 'number',
                    label: 'EXPORTACIONES',
                    disabled: false,
                    required: false,
                },
                importaciones: {
                    type: 'number',
                    label: 'IMPORTACIONES',
                    disabled: false,
                    required: false,
                },
                porc_circ_lp_otra_divisa: {
                    type: 'number',
                    label: 'PROC. CIRC. LP OTRA DIVISA',
                    disabled: false,
                    required: false,
                },
                otros_gastos_ng_en_efectivo: {
                    type: 'number',
                    label: 'OTROS GASTOS (NG) EN EFECTIVO',
                    disabled: false,
                    required: false,
                },
                perd_ut_extraord_en_efectivo: {
                    type: 'number',
                    label: 'PERD(UT) EXTRAORD. EN EFECTIVO',
                    disabled: false,
                    required: false,
                },
                
                
            }
        },
    },
    blanks: {
        info_economica: {
            activo: {
                activo: 0,
                activo_circulante: {
                    activo_circulante: 0,
                    caja_y_bancos: 0,
                    inversiones_temporales: 0,
                    clientes_y_doctos_x_cobrar: 0,
                    inventarios: 0,
                    cuentas_por_cobrar_a_filiales: 0,
                    pagos_anticipados: 0,
                    otros_activos_circ_operativos: 0,
                    deudores_diversos: 0,
                    otros_activos_circulantes_no_operativos: 0,
                },
                activo_fijo: {
                    activo_fijo: 0,
                    propiedades_planta_y_equipo_neto: 0,
                    inversiones_en_subsidiarias_afiliado: 0,
                    cuentas_por_cobrar_afililares: 0,
                    otros_activos_fijos: 0,
                },
                otros_activos: {
                    otros_activos: 0,
                    activo_por_impuesto_diferido: 0,
                    patentes_licencias_y_marcas: 0,
                    credito_mercantil: 0,
                    cargos_diferidos: 0,
                }
            },
            pasivo: {
                pasivo_corto_plazo: {
                    pasivo_corto_plazo: 0,
                    bancomer_corto_plazo: 0,
                    otros_bancos_corto_plazo: 0,
                    emisiones_de_deuda: 0,
                    porc_circ_largo_plazo_bancom: 0,
                    porc_circ_largo_plazo_otros_b: 0,
                    proveedores: 0,
                    impuestos_por_pagar: 0,
                    cuentas_por_pagar_filiales: 0,
                    gastos_acumulados: 0,
                    otros_pasivos_circ_operativos: 0,
                    otros_pasivos_circ_no_operativos: 0,
                },
                pasivo_largo_plazo: {
                    pasivo_largo_plazo: 0,
                    bancomer_largo_plazo: 0,
                    otros_bancos_largo_plazo: 0,
                    emisiones_de_deuda: 0,
                    cuentas_por_pagar_filiales: 0,
                    otros_pasivos_largo_plazo: 0,
                    primas_de_antiguedad: 0,
                    pasivos_diferidos: 0,
                    pasivos_por_impuestos_diferidos: 0,
                },
                capital_contable: {
                    capital_contable: 0,
                    capital_social_actualizado: 0,
                    aportaciones_pendiente_captital: 0,
                    prima_de_emisiones_de_acciones: 0,
                    interes_minoritario: 0,
                    exc_insuf_actualizado_cap_c: 0,
                    impac_isr_diferido: 0,
                    reservas: 0,
                    utilidades_retenidas: 0,
                    utilidad_del_ejercicio: 0,
                }
            },
            cuenta_de_resultados: {
                ventas_netas_exportacion: 0,
                ventas_netas_nacionales: 0,
                ventas_netas_totales: 0,
                costo_de_ventas: 0,
                utilida_bruta: 0,
                amortizacion: 0,
                depreciacion: 0,
                gastos_de_venta_y_administracion: 0,
                uafir: 0,
                perdida_utilidad_cambiaria: 0,
                perdida_utilidad_monetaria: 0,
                productos_financieros: 0,
                gastos_financieros: 0,
                costo_integral_de_financiamiento: 0,
                otros_gastos_ingresos: 0,
                utilidad_antes_de_isr_y_ptu: 0,
                impuestos_y_ptu_diferido: 0,
                impac_causado: 0,
                ptu_causado: 0,
                isr_causado: 0,
                utilidad_antes_de_part_extraordinaria: 0,
                perdida_utilidad_subafiliada: 0,
                perdida_utilidad_extraordinaria: 0,
                utiliad_neta: 0,
            },
            conciliaciones: {
                inversiones_netas_en_inventarios: 0,
                inversion_subs_af: 0,
                dividendos_subs_af: 0,
                inv_neta_en_prop_pta_equipo: 0,
                aportacion_accionista_en_efectivo: 0,
                dividentos_pagados_en_efectivo: 0,
                perd_ut_cambiaria_cp: 0,
                perd_ut_cambiaria_lp: 0,
                perd_ut_cambiaria_en_clientes: 0,
                perd_ut_cambiaria_en_inventarios: 0,
                perd_ut_cambiaria_en_proveedores: 0,
                exportaciones: 0,
                importaciones: 0,
                porc_circ_lp_otra_divisa: 0,
                otros_gastos_ng_en_efectivo: 0,
                perd_ut_extraord_en_efectivo: 0,
            }
        },
        info_cualitativa: {
            puntuacion_cuantitativa: '41.91',
            puntuacion_cuantitativa_sobre: '80.00',
            puntuacion_cualitativa: '0.00',
            puntuacion_cualitativa_sobre: '20.00',
            puntuacion_total: '0.00',
            variables_a_explicar: '2',
            variables_condicionales: '0',
            rating_cliente: '',
            p_riesgo: '',
            socio_principal: '',
            ventas_consolidadas_del_grupo: '',
            analisis: analisis
        }
    },
    qualitative_items: qualitative_items
}
