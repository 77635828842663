<script>
import TabsVue from '@/components/CreditLens/TabsVue.vue';
import AnalysisConfig from '@/components/CreditLens/Details/Analysis/AnalysisConfig.vue';
import HistoryPeriod from '@/components/CreditLens/Details/Analysis/HistoryPeriod.vue'
import AnalysisProjection from '@/components/CreditLens/Details/Analysis/AnalysisProjection.vue';
import UserDefinedAnalysis from '@/components/CreditLens/Details/Analysis/UserDefinedAnalysis.vue';
import AnalysisReports from '@/components/CreditLens/Details/Analysis/AnalysisReports.vue';
export default {
    components: {
        TabsVue
    },
    data() {
        return {
            tabs: [{
                    title: 'Configuración de análisis',
                    component: AnalysisConfig
                }, 
                {
                    title: 'Período histórico',
                    component: HistoryPeriod
                },
                {
                    title: 'Proyección',
                    component: AnalysisProjection
                },
                {
                    title: 'Análisis definido por usuario',
                    component: UserDefinedAnalysis
                },
                {
                    title: 'Informes',
                    component: AnalysisReports
                },
            ]
        }
    }
}
</script>
<template>
    <div class="pt-3">
        <TabsVue :tabs="tabs" :selected-tab="1"/>
    </div>
</template>