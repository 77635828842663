<script>
export default {
    props: {
        size: {
            type: String,
            default() {
                return 'w-1/4'
            }
        }
    },
    data() {
        return {
            showing: false
        }
    },

    methods: {
        open() {
            this.showing = true
        },

        close() {
            this.showing = false
        }
    }
}
</script>
<template>
    <div  class="modal" v-if="showing">
        <div class="modal__wrap" :class="size">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.modal{
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    &__wrap{
        min-width: 400px;
        background: #fff;
        min-height: 200px;
        margin: auto;
    }
}
</style>