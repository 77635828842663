export default [
    {
        title: 'Administración de entidades',
        route: 'CreditLensDetailsIndex',
        active: false,
        submenu: [
          {
            title: 'Información de la entidad',
            route: 'CreditLensDetailsIndex',
            active: false,
          },
          {
            title: 'Alertas de entidad',
            route: null,
            active: false,
          },
          
        ]
    }, {
        title: 'Análisis financiero',
        route: 'CreditLensDetailsAnlysis',
        active: false,
        submenu: []
    }
]