<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            'loadingStatus': 'loading/status',
            'loadingMessage': 'loading/message'
        })
    }
}
</script>
<template>
    <div class="loading" v-if="loadingStatus">
        <div class="loading__wrap">
            <div class="loading__message">
                <p>{{ loadingMessage }}</p>
                <p>Espere por favor...</p>
            </div>
            <div>
                <div class="cssProgress">
                    <div class="progress1">
                        <div class="cssProgress-bar cssProgress-active" data-percent="75" style="width: 100%"> 
                            <span class="cssProgress-label"></span> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    z-index: 1000;
    &__wrap{
        margin: auto;
        width: 400px;
        background-color: #fff;
        padding: 1rem 4rem;
        border-radius: 0.25rem;
    }
    &__message{
        text-align: center;
        font-weight: 600;
        color: #056fb6;
    }
}
</style>